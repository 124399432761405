.total-order {
	font-weight: bold;
    border-bottom: 1px solid $color-border;
    padding-bottom: .95rem;
    margin-top: 0;
    margin-bottom: .47rem;
}
.results-perPage { font-weight: bold; margin-top: 1.24rem; }
.pos-page-select { float: right; }
.pagination {
	font-weight: bold;
	color: $color-body;
	margin-top: 0;
	li {
		display: block!important;
		float: left;
		line-height: inherit;
		a { line-height: inherit; }
		&:first-child, &:last-child { margin-top: 1.24rem; }
	}
	.active { color: $color-green; padding: 0 0.625rem; }
	a:hover { color: $color-green; }
	.laquo, .raquo, .sr-only { display: none!important; }
	.previous, .next {
		a, span {
			height: 1.063rem;
			width: 0.688rem;
			display: block;
			padding: 0;
			position: relative; 
			margin: 0.188rem 0 0;
			&::after {
				display: block;
				height: 0.938em;
				position: absolute;
				left: -0.250rem;
				top: -0.11rem;
				width: 1.313em;
				font-family: 'icomoon';
				color: $color-darkgrey;
				content: "\e901";
			}
		}
	}
	.next {
		a, span {
			&::after { content: "\e902"; left: -0.125rem; }
		}
	}
	.next {	padding-left: 0.75rem!important; }
	.disabled {
		padding: 0;
		&.next, &.previous {
			span { opacity: 0.3; }
		}
	}
}
.page-previous {
    display: block;
    height: 0.938em;
    position: absolute;
    left: 0;
    top: -0.188rem;
    width: 1.313em;
    font-family: 'icomoon';
    color: #808080;
    content: "\e901";
}
select.page-size-selector { width: 4.4rem; margin: .5rem 0 0 .8rem; }
.table-total, .white-box-pagination {
	display: block;
	&.retoure-btn {
		float: left; width: 100%;
		.hide-for-small-only { padding-left: 0; }
	}
	form { padding-right: 0; }
	.back {
		color: $color-body;
		display: block;
		font-weight: bold;
		margin: 0.938rem 0 0 0;
		.arrow {
			width: 0;
			height: 0;
			border-top: 0.375rem solid transparent;
			border-bottom: 0.375rem solid transparent;
			border-right: 0.500rem solid $color-body;
			float: left;
			margin: 0.438rem 0.438rem 0 0;
		}
		span { float: left; }
	}
}
.paging { padding: 0; }

@media (max-width: 47.9375em) {	
	.pos-page-select {
		float: none;
		text-align: center;
    	display: inline-block;
    	margin-left: -0.188rem;
	}
	main #content {
		.white-box-pagination { background-color: transparent; }
	}
}