// Extra rules for the Pricing Service shop tab implementation

.shop-switcher {
    z-index: 20;

    &--item.active span, &--item:hover span {
        color: $color-baywadarkgrey;
        padding-left: 5px;
    }
}

