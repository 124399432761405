$namespace: "ps-";

.#{$namespace}error-page {
	$root: &;
	background-color: $color-baywagreen;
	padding: rem-calc(100) rem-calc(70);
	position: relative;
	color: $color-baywawhite;
	&__content {
		background: transparent;
		z-index: 1;
		position: relative;
		&__h1 {
			font-size: rem-calc(40);
			line-height: 2.4rem;
			margin-bottom: 2.4rem;
			font-weight: lighter;
			@include breakpoint(small only) {
				font-size: rem-calc(22);
				line-height: 1.181em;
				margin-bottom: 1.181em;
			}
		}

		&__btn {
			color: #ffffff;
			box-shadow: inset 0 0 0 2px $color-baywawhite;
			width: rem-calc(255) !important;
			font-weight: normal !important;

			&:hover {
				background-color: $color-baywawhite !important;
				color: $color-baywagreen !important;
			}
			margin-top: rem-calc(50);
		}

		#{$root}__info {
			float: left;
			margin-top: rem-calc(30);
		}

	}

	&__error-code {
		position: absolute;
		right: -.5rem;
		bottom: 7.4rem;
		font-size: 21.875rem;
		font-weight: 600;
		line-height: 0;
		opacity: 0.1;
		@include breakpoint(medium only) {
			font-size: 18.75rem;
			bottom: 6.25rem;
		}
		@include breakpoint(small only) {
	        font-size: 10rem;
		    right: -0.2rem;
		    bottom: 7.2rem;
		}
	}
	&-wrap {
		background-color: $color-baywagreen;
	}
}

header {
	margin-bottom: 0;
}
	
main {
    padding: 0;
}

@media screen and (max-width: 47.9375em) {
	.ps-error-page {
		padding: 2rem;
	}
}