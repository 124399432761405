$namespace: "bw";
form, fieldset {
	border: 0;
	clear: both;
	margin: 0;
	padding: 0;
}

.#{$namespace} {
	&-form {
		&-datepicker {
			background-color: $color-baywawhite;
			border-radius: 0.188rem;
			position: relative;
			.icon-calendar {
				color: $color-baywadarkgrey;
				font-size: 1.500rem;
				right: 0.625rem;
				top: 0.438rem;
				margin-right: -0.188rem;
				position: absolute;
				z-index: 0;
			}
			input {
				background-color: transparent;
				border-radius: 0;
				position: relative;
				z-index: 1;
				&:focus {
					border: 1px solid $color-baywayellow;
				}
			}
		}
		&__group {
			position: relative;
			&.ico-x:after, &.ico-i:after, &.ico-checkmark:after {
				position: absolute;
				bottom: 0.500rem;
				right: 0.500rem;
				font-size: 1.563rem;
				font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
			&.ico-x:after {
				content: "\e919";
				color: $color-baywagrey1;
				cursor: pointer;
			}
			&.ico-i:after {
				content: "\e921";
				color: $color-baywagreen;
				cursor: pointer;
			}
			&.ico-checkmark:after {
				content: "\e908";
				color: $color-baywagreen;
				cursor: pointer;
				font-size: 1.1rem;
				bottom: 0.6rem;
			}
			&__multicolumn-left{
				@include breakpoint(medium up) {
					margin-right: 0.625rem !important;
				}
			}
			&__multicolumn-right{
				@include breakpoint(medium up) {
					margin-left: 0.625rem !important;
				}
			}
			&-small {
				width: 9.000rem;

				&.ico-x:after, &.ico-i:after, &.ico-checkmark:after {
					right: 0.500rem;
				}
			}

			.bw-form__infotxt {
				position: absolute;
				background-color: $color-baywadarkgrey;
				color: $color-baywawhite;
				font-weight: 500;
				font-size: 0.875rem;
				margin-top: -0.188rem;
				padding: 0.938rem 0.625rem;
				width: 100%;
				z-index: 10;
			}
			.bw-form__infotxt:after {
				content: "";
				position: absolute;
				bottom: 100%;
				right: 0.813rem;
				border-width: 0.500rem;
				border-style: solid;
				border-color: transparent transparent $color-baywadarkgrey transparent;
			}
		}
		&__label {
			font-weight: 600;
			font-size: 0.875rem;
			line-height: 1.125rem;
			margin: 0 0 0.313rem 0;
		}
		&-radio {
			display: inline-block;
			width: 100%;
			&__btn {
				float: left;
				margin: 0 2.500rem 0.375rem 0;
				position: relative;

				input[type="radio"] {
					line-height: normal;
					position: absolute;
					visibility: hidden;
					&:checked ~ .bw-form-radio__btn--checked::before {
						background: $color-baywagreen;
					}
					&:disabled ~ label[for] {
						cursor: no-drop;
					}
					&:disabled ~ .bw-form-radio__btn--checked {
						background: $color-baywagrey3;
					}
					+ label[for] {
						cursor: pointer;
					}
				}
				label {
					display: inline-block;
					font-weight: normal;
					line-height: 1.8;
					padding: 0 0 0 2.125rem;
					z-index: 10;
				}
				&--checked {
					background: $color-baywawhite;
					display: block;
					border: 0.063rem solid $color-baywagrey2;
					border-radius: 100%;
					height: 1.563rem;
					left: 0;
					position: absolute;
					top: 0.063rem;
					width: 1.563rem;
					z-index: -1;
					transition: border .25s linear;
					-webkit-transition: border .25s linear;
					&::before {
						display: block;
						position: absolute;
						content: '';
						border-radius: 100%;
						height: .938rem;
						width: .938rem;
						top: .250rem;
						left: .250rem;
						margin: auto;
						transition: background .25s linear;
						-webkit-transition: background .25s linear;
					}
				}
			}
		}
		&-checkbox {
			position: relative;
			margin: 0.625rem 0 0;
			display: inline-block;
			width: 100%;

			&-teaser {
				margin: 0;
			}
			span {
				float: left;
				line-height: 1.563rem;
				margin-left: 2.187rem;
			}
			input[type=checkbox] {
				float: left;
				visibility: hidden;
			}
			input {
				width: 1.563rem;
				height: 1.563rem;
				margin: 0 1.063rem 0 0;
				position: relative;
			}
			label {
				cursor: pointer;
				position: absolute;
				width: 1.563rem;
				height: 1.563rem;
				top: 0;
				left: 0;
				margin: 0;
				background: $color-baywawhite;
				border: 0.063rem solid $color-baywagrey2;
			}
			label:after {
				opacity: 0;
				content: '';
				position: absolute;
				width: 0.813rem;
				height: 0.813rem;
				background: $color-baywagreen;
				top: 0.313rem;
				left: 0.313rem;
			}
			input[type=checkbox]:checked ~ label:after {
				opacity: 1;
			}
		}
		&-showpass {
			position: relative;
			&__trigger {
				color: $color-baywadarkgrey;
				cursor: pointer;
				font-weight: bold;
				font-size: 0.875rem;
				right: 0.625rem;
				top: 2.500rem;
				position: absolute;
			}
		}
		&__input {
			background-color: $color-baywawhite;
			border: 0.063rem solid $color-baywagrey2;
			border-radius: 0 !important;
			box-shadow: none;
			box-sizing: border-box;
			display: block;
			font-weight: 500;
			font-size: 0.875rem;
			height: 2.500rem;
			line-height: 2.500rem;
			margin: 0 0 1.000rem;
			outline: none;
			padding: 0 0.625rem;
			width: 100%;
			&::placeholder {
				color: $color-baywagrey1;
			}
			&:-ms-input-placeholder {
				color: $color-baywagrey1;
			}
			&::-ms-input-placeholder {
				color: $color-baywagrey1;
			}
			&--clear {
				padding-right: 2.125rem;
			}
			&--error {
				border-color: $color-baywared;
				color: $color-baywared;
			}
		}
	}
}
