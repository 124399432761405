$namespace: "bw";

.#{$namespace} {
    &-ul-list {
      display: inline-block;
      list-style: outside none none;
      margin: 0 0 0.625rem 0;
      padding: 0;
      &--checkmark li {
        position: relative;
        padding: 0 0 0 1.563rem;
        margin: 0 0 0.438rem 0;
        &::before {
          color: $color-baywagreen;
          content: "\e908";
          font-family: 'icomoon';
          left: 0;
          position: absolute;
          top: 0;
        }
      }
    }
	&-teaser {
		background-color: $color-baywawhite;
		-webkit-box-shadow: 1px 1px 10px -1px rgba(0,0,0,.3);
		-moz-box-shadow: 1px 1px 10px -1px rgba(0,0,0,.3);
		box-shadow: 1px 1px 10px -1px rgba(0,0,0,.3);
        margin: 1.25rem 0;
		&.no-shadow {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			.bw-teaser-help {
				&__head {
					background-color: $color-baywawhite; padding: 1.25rem 1.25rem 0 1.25rem;
					h2 { font-size: 1.375rem; color: $color-baywagreen; margin: 0; }
				}
				&__content {
					padding: .5rem 1.25rem 0;
					p {  margin: 0 0 1.8rem; }
				}
			}
		}
		&-help	{
			&__head { 
				background-color: $color-baywagrey3; padding: 1.250rem;
				h2 { font-size: 1.375rem; color: $color-baywadarkgrey; margin: 0; }
			}
			&__content { 
				padding: 1.250rem 1.250rem 0;
				p { 
					margin: 0 0 1.000rem;
				}
				a {
					border-bottom: 1px solid $color-baywagrey2;
					display: inline-block;
					font-size: 1.000rem;
					min-height: 5.250rem;
					text-decoration: none;
					width: 100%;
					&:hover { color: $color-baywadarkgrey; }
					&:last-child { border-bottom: none; }
					.icon { 
						color: $color-baywagreen;
                       font-size: 1.750rem;
						float: left;
                       margin: 1.625rem 0.938rem 0 0;
						&.icon-phone {
						  font-size: 2.125rem;
                          margin: 1.438rem 0.938rem 0 0;
						}
					}
				}
                &-txt {
                  color: $color-baywadarkgrey;
                  display: block;
                  float: left;
                  margin: 1.938rem 0;
                  width: 74%;
                  b { color: $color-baywagreen; font-weight: normal; }
                }
				.bw-ul-list--checkmark {
					margin: 0;
					padding: 0.313rem 0 0;
					li {
						font-size: 1.000rem;
						padding: 0 0 1.250rem 2.188rem; 
						&::before { 
							font-size: 1.313rem;
						}
					}
				}
			}
		}
		&-overview {
			height: 17.813rem;
			overflow: hidden;
			position: relative;
			margin: 0 0 1.563rem;
			padding: 9.375rem 1.250rem 0.938rem;
			background: $color-baywawhite;
			.icon {
				background-color: $color-baywagreen;
				color: $color-baywawhite;
				font-size: 5.938rem;
				height: 9.375rem;
				left: 0;
				padding-top: 1.563rem;
				position: absolute;
				text-align: center;
				top: 0;
				width: 100%;
			}
			&__triangle {
				border-left: 1.250rem solid transparent;
				border-right: 1.250rem solid transparent;
				border-top: 0.625rem solid $color-baywagreen;
				height: 0;
				width: 0;
				margin: auto;
			}
			h2, p { text-align: center; font-weight: normal; }
			h2 { color: $color-baywadarkgrey; font-size: 1.375rem; line-height: 1.625rem; margin: 1.125rem 0; }
			p { color: $color-baywagrey1; }
			
			&--hover {
				position: absolute;
				top: 17.813rem;
				left: 0;
				transition: all .4s ease-out;
				width: 100%;
				border-top: 2.500rem solid $color-baywagreenhover;
				padding: 0 1.250rem 0.938rem;
				.bw-teaser-overview__triangle { border-top: 0.625rem solid $color-baywagreenhover; }
				@include breakpoint(small only) {
    				display: none;
  				}
			}
		}
	}
	&-overview-link {
		text-decoration: none;
		.bw-teaser {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		&:hover {
			.bw-teaser-overview {
				-webkit-box-shadow: 1px 1px 10px -1px rgba(0,0,0,.3);
				-moz-box-shadow: 1px 1px 10px -1px rgba(0,0,0,.3);
				box-shadow: 1px 1px 10px -1px rgba(0,0,0,.3);
			}
			.bw-teaser-overview--hover { box-shadow: none; top: 0; }
		}
	}
}