
	body #CybotCookiebotDialog a, #CybotCookiebotDialog div, #CybotCookiebotDialogBodyContentControls, #CybotCookiebotDialogBodyContentTitle {
		font-family: 'BayWa Sans', Arial, sans-serif !important;
	}

	body #CybotCookiebotDialog {
		border: none;
		border-radius: 0;
		box-shadow: 4px 4px 14px #666;
		margin-left: 5vw !important;
		margin-top: 5vh !important;
		max-width: none !important;
		padding: 0 !important;
		position: absolute;
		width: 90vw !important;
		transform: none!important;
		transform-origin: center!important;

		@include breakpoint(medium) {
			position: fixed;
		}
		@include breakpoint(large) {
			margin-left: 25vw !important;
			width: 50vw !important;
		}
	}

	body #CybotCookiebotDialogBody {
		padding: 20px 20px 12px 20px!important;
		width: auto !important;
		@include breakpoint(medium) {
			padding: 40px 40px 32px 40px!important;
		}
	}

	body #CybotCookiebotDialogBodyContent {
		padding: 0 !important;
	}

	body #CybotCookiebotDialogBodyContentTitle {
		color: #008c46;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2rem;
	}

	body #CybotCookiebotDialogBodyContent #CybotCookiebotDialogBodyContentText {
		color: #333;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.25rem;
		margin-top: 15px;
		margin-bottom: 10px;
		@include breakpoint(medium) {
			font-size: 1rem;
			line-height: 1.375rem;
		}
	}

	body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
		color: #008c46;
		text-decoration: none;
	}

	body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:hover {
		color: #006F3B;
		text-decoration: none;
	}

	body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll, body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll
	{
		border: none !important;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 1rem;
	font-weight: 600;
	height: 2.5rem;
	line-height: 2.5rem;
		padding: 0 !important;
	text-decoration: none;
	text-align: center;
	transition: background-color 0.2s linear, color 0.2s linear;
	}

	body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
		background-color: #fff;
		color: #008c46;
		outline: solid 1px #008c46;
		outline-offset: -1px;
		width: 100% !important;
		margin: 20px 0 0 0;
	@include breakpoint(medium) {
		width: 49% !important;
		margin: 0 1% 0 0;
	}
	}

	body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover {
		background-color: #008c46;
		color: #fff;
	}

	body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
		background-color: #008c46;
		width: 100% !important;
		margin: 0;
		@include breakpoint(medium) {
			margin: 0 0 0 1%;
			width: 49% !important;
	}
	}

	body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover {
		background-color: #006F3B;
	}

	body #CybotCookiebotDialogBody #CybotCookiebotDialogBodyLevelWrapper {
		padding: 16px 0 !important;
		float: left !important;
		width: 100%;
	}

	body #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
		display: flex !important;
		flex-direction: column-reverse;
		float: none;
		padding: 0;
		text-align: left;
		@include breakpoint(medium) {
			flex-direction: row;
		}

	}

	body #CybotCookiebotDialogBodyLevelButtons {
		font-size: 10px;
		padding: 0 !important;
		margin: 30px 0 0 0 !important;
		width: 100% !important;
	}

	body #CybotCookiebotDialogBodyLevelButtonsTable {
		margin: 0;
		padding: 0;
		width: 100% !important;
	}

	body #CybotCookiebotDialogBodyLevelButtonsRow {
		margin: 0;
		padding: 0;
	}


	body #CybotCookiebotDialogBodyLevelDetailsWrapper {
		background-color: #fff;
		padding: 4px 15px;
	}

	body #CybotCookiebotDialogBodyLevelDetailsWrapper a {
		font-size: 10px;
		padding-left: 8px;
	}

	body #CybotCookiebotDialogBodyLevelButtonsSelectPane {
		border-radius: 0;
		padding: 4px 0 5px 0;
	}

	body #CybotCookiebotDialogDetail {
		color: #333 !important;
	}

	body #CybotCookiebotDialogDetail #CybotCookiebotDialogDetailBody {
		padding: 0 20px 20px;
		@include breakpoint(medium) {
			padding: 0 40px 40px;
		}
	}

	body #CybotCookiebotDialogDetail #CybotCookiebotDialogDetailBody .CybotCookiebotDialogDetailBodyContentTab:first-child {
		border-left: 1px solid #cccccc;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}

	body #CybotCookiebotDialogDetail #CybotCookiebotDialogDetailBody #CybotCookiebotDialogDetailBodyContent {
		color: #333;
		border-width: 1px;
	}

