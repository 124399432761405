$namespace: "ps";

.#{$namespace}-thankyou {
  &-contact {  
    &__row {
      padding-top: 20px;
      padding-bottom: 20px;
      &-telephone {
        padding-top: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-grey;
          .icon {
		  	color: #008c46;
		  	font-size: 2.125rem;
		    float: left;		
			}
		  .text {
        	padding-left: 5px;
        	padding-top: 5px;
        	position: absolute;
          }
      }
      &-mail {
        padding-top: 20px;
        padding-bottom: 0px;
          .icon {
		  	color: #008c46;
		  	font-size: 2.125rem;
		    float: left;		
			}
	      .text {
        	padding-left: 5px;
        	padding-top: 5px;
        	position: absolute;
        	color: #0a0a0a;
      	  }		
      }
      &-title {
      	background: #999;
      	color: #fff;
      	font-size: 1.375rem;
        padding-top: 15px;
        padding-bottom: 5px;
        padding-left: .625rem !important;
        padding-right: .625rem !important;
      }      
    }
  }
  
  
  &-confirmation {
    &__row {
      padding-top: 13px;
      padding-bottom: 15px;
      &-title{
      	padding-top: 5px;
      	padding-bottom: 5px;
      	color: #008c46;
      }
      &-btn{
        padding-top: 10px;
      	padding-bottom: 7px;
      }
      .green-text {
		color: #008c46;
      }      
    }
  }  
}