$namespace: ".bw";

#{$namespace}-tooltip {
  $root: &;
  position: relative;
  text-decoration: none;
  &:after {
    content: attr(data-tooltip);
    position: absolute;
    top: rem-calc(40);
    right: 0;
    background: $color-baywadarkgrey;
    padding: .938rem .625rem;
    color: $color-baywawhite;
    font-weight: 500;
    font-size: 0.875rem;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    width: rem-calc(714);
    @include breakpoint(small only) {
      width: 90vw;
      left: 5%;
      transform: translate(-50%,0%);
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: 50%;
    top: rem-calc(24);
    border-width: 0.500rem;
    border-style: solid;
    border-color: transparent transparent $color-baywadarkgrey transparent;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    visibility: hidden;
    opacity: 0;
    z-index: 99;
  }
  &:hover::after {
    top: rem-calc(35);
    opacity: 1;
    visibility: visible;
  }
  &:hover::before {
    top: rem-calc(19);
    opacity: 1;
    visibility: visible;
    .maut-tooltip {


    }
  }

  &--left-box {
    &:after {
      right: rem-calc(-100);
      @include breakpoint(medium only) {
        width: 80vw;
        left: -5vw;
      }
      @include breakpoint(small only) {
        width: 80vw;
        left: 23vw;
      }
    }
  }

  &--right-box {
    &:after {
      @include breakpoint(large up) {
        right: rem-calc(-100);
      }
    }
  }

  &--icon {
    &:before {
      top: rem-calc(34) !important;
    }
    &:after {
      top: rem-calc(50) !important;
    }
    &:hover {
      &::before {
        top: rem-calc(29) !important;
      }
      &::after {
        top: rem-calc(45) !important;
      }
    }
  }
}

.maut-tooltip01:before {
  @include breakpoint(small only) {
    top: 1rem;
    left: 0.5rem;
  }
}

.maut-tooltip01:after {
  @include breakpoint(small only) {
    width: 90vw;
    top: 2rem;
    left: -60%;
  }
}

.abschlauch-tooltip01:after {
  @include breakpoint(small only) {
    left: 52%;
  }
}

.maut-tooltip02a:after {
  right: -300%;
  @include breakpoint(medium only) {
    right: -86%;
  }
  @include breakpoint(small only) {
    right: 0;
    left: -71%;
  }
}

.abschlauch-tooltip02a:after {
  right: -51%;
  @include breakpoint(medium only) {
    right: 0;
  }
  @include breakpoint(small only) {
    right: 0;
    left: 50%;
  }
}

.maut-tooltip02b:after {
  @include breakpoint(small only) {
    left: -72%;
  }
}

.abschlauch-tooltip02b:after {
  @include breakpoint(small only) {
    left: 50%;
  }
}
