$namespace: "ps";

.#{$namespace}-teaser-help__content-txt {
  margin-top: 1.7rem;
}

.#{$namespace}-form {
  background: $color-white;
  padding: 1.25rem;
  position: relative;
  float: left;
  margin: 0 0 1.563rem 0;
  width: 100%;

  // invert visibility condition because of IE problem for 767px width
  display: none;
  @include breakpoint(medium) {
    display: block;
  }

  &__search-filter-opener {
    margin: 0 0 1.25rem;
    position: relative;
    border-radius: 0;
    &::after {
      font-family: icomoon;
      color: $color-baywagreen;
      content: "caretdown";
      display: block;
      font-size: 16px;
      height: 8px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 0;
  	}
    // font-family: icomoon;
    // color: $color-baywagreen;
    // content: "\E900";
    // display: block;
    // font-size: 1rem;
    // height: 0.5rem;
    // pointer-events: none;
    // position: absolute;
    // right: 0.75rem;
    // top: 0;
  }

  &__title {
    margin-bottom: 0;
    @include breakpoint(small only) {
      font-size: 1.5625rem;
    }
  }

  &-searchfields {
    padding-top: 1.5rem;
    padding-bottom: 0.75rem;
  }

  &-filter {
    padding-top: 0.5rem;
  }

  &-submit {
    padding-top: 0.75rem;
    @include breakpoint(small only) {
      padding-top: 0.35rem;
    }
  }

  &__select {
    width: 100% !important;
    height: 2.5rem !important;
    line-height: 2.5rem !important;
    border-radius: 0 !important;
    margin-bottom: 1rem;
    .list {
      width: 100%;
      border-radius: 0 !important;
    }
    span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__fields-wrap {
    width: 100%;
    height: rem-calc(65);
    display: inline-block;
  }

  &__input {
    width: 50%;
    height: rem-calc(45);
    float: right;
    padding-left: rem-calc(10);
    padding-right: 0;
    position: relative;
    border-radius: 0 !important;

    &--first {
      padding-right: rem-calc(10);
      padding-left: 0;
      float: left;
    }

    &__field {
      color: $color-black !important;
      text-align: right !important;
      background-color: $color-white !important;
    }

    &__label {
      position: absolute;
      display: block;
      left: rem-calc(10);
      top: rem-calc(12);
      z-index: 9;
      color: $color-darkgrey;
      &--second {
        left: rem-calc(20);
      }
    }
  }

  &__submit-btn {
    margin-top: rem-calc(20) !important;
    @include breakpoint(medium only) {
      float: right;
    }
  }

  &__required-info-1, &__required-info-2 {
    text-align: right;
    float: right;
    height: inherit;
    padding-top: .5rem;
    font-size: .875rem;
  }

  &__no-left-padding {
    padding-left: 0!important;
  }

  &__no-right-padding {
    padding-right: 0!important;
  }

  &__checkbox-divergent-invoice-addressData {
    margin-left: -0.9rem!important;
  }

  &__span-divergent-invoice-addressData {
    margin: 0!important;
    width: 80%!important;
  }

  &__ggvs-info {
    color: $color-baywagreen;
    font-size: 1.2rem;
    float: left;
    margin: 0.1rem 0 0 0.2rem;
  }

  &__row_w_sticky_contents {
    display: inherit;
  }

  &__sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 1.25rem;
  }
}

#energyPricingFormSubmit {
  @include breakpoint(medium) {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width:768px) {
  .ps-form__required-info-1 {
    display: block;
  }
  .ps-form__required-info-2 {
    display: none;
  }
  .ps-form__checkbox-divergent-invoice-addressData {
    margin-left: -0.9rem!important;
  }
}

@media screen and (min-width:769px) {
  .ps-form__required-info-2 {
    display: block;
  }
  .ps-form__checkbox-divergent-invoice-addressData {
    margin-left: -0.6rem!important;
  }
}

.bw-form-checkbox-teaser {
    margin: 0 0 15px 0;
}