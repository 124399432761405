.bw-teaser.no-shadow .bw-teaser-help__head h2 {
    font-size: 1.75rem;
    margin-bottom: 1.8rem;

    &.bw-teaser__headline {
        margin-bottom: 1rem;
    }
}

.bw-teaser {
  margin: 0 0;
  height: 425px;

  @include breakpoint(small down) {
    margin: 1.25rem 0;
  }

  &__oilfox {
      margin-top:0;
      }

  &__button {
      width: 66%;
      margin-top: 48px !important;
      margin-left: 34%;
	  display: inline;

      @include breakpoint(small down) {
        width: 100%;
        margin-left: 0;
      }
  }

  &-help {

    &__content {
      padding-top: 0!important;

      &-txt {
        margin: 1rem 0;
      }

      div {
        padding: 2.5rem 0;
        border-bottom: 1px solid $color-baywagrey2;

        &:first-of-type {
          padding-top: 1.5rem;
        }

        &:last-child {
          border-bottom: none;
        }

        p {
          text-align: center;
        }
      }

      a {
        min-height: 4.5rem;

        &:last-child {
          border-bottom: none;
        }

        .icon.icon-phone {
          font-size: 2.125rem;
          margin: 0.5rem 0.938rem 0 0;
        }

        .icon {
          margin: 0.9rem 0.938rem 0 0;
        }

        &:hover {
          color: $color-baywagreenhover;
          text-decoration: none;
        }
      }

      p {
        margin: 0 0 1rem;
      }

      .bw-ul-list {

        &--checkmark {
          margin: 0;
          padding: 0.313rem 0 0;

          li {
            font-size: 1rem;
            padding: 0 0 1.25rem 2.188rem;
            margin: 0 0 0.438rem 0;

            &::before {
              font-size: 1.313rem;
            }
          }
        }
      }
    }
  }
}

.product-teaser {
	padding-top: 0;	
   	padding-left: 0;
}

.logo-combayn {
	float: right;	
   	width: 245px;


@include breakpoint(medium down) {
            float: none;
		    display: block;
		    margin-left: auto;
		    margin-right: auto;
			width: 200px;
   			margin-top: 15px;
      }
}

.btn-coupon {	
   	margin-top: 50px;
}

.relative {
  position: relative;
}

.discount_banner {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px 16px;
  line-height: 1;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  background-color: $color-baywared;
  color: $color-baywawhite;
  text-align: center;
  z-index: 1;

  a {
    color: $color-baywawhite;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.discount_banner_box {
  @include breakpoint(small only) {
      padding-top: 2.5rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .bw-teaser {
      height: 450px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 904px) {
  .bw-teaser {
      height: 520px;
  }
}

@media only screen and (max-width: 374px) {
  .bw-teaser {
      height: 480px;
  }
}

@media only screen and (max-width: 1239px) {
  .combayn-hero-text {
      font-size: 0.875rem;
  }
}
