.leftnav { 
	nav { background: $color-white; padding: 0.595rem 1.25rem; }
	ul li.active li.active a { color: $color-green;	}
	li {
		border-top: 1px solid $color-border;
		line-height: 3.375rem;
		cursor: pointer;
		float: none;
		margin: 0;
		a {
			color: $color-body;
			display: inline-block;
			line-height: 3.375rem;
			width: 100%;
			position: relative;
			z-index: 1;
			&:hover, .active { color: $color-green; }
		}
		&:first-child { border-top: none; }
		&.has-sub { position: relative; }
	}
	li li {	border-top: none; padding-left: 1.25rem; }
	li.active {
		font-weight: bold;
		li.active { font-weight: bold; }
	}
	li.active li { font-weight: normal; }
	.has-sub::after {
		font-family: 'icomoon';
		color: $color-darkgrey;
		content: "\e902";
		display: block;
		height: 1.875rem;
		right: -0.938rem;
		top: 0;
		position: absolute;
		width: 1.875rem;
	}
	.has-sub.active::after { content: "\e900"; right: -0.625rem; }
	.has-sub ul { display: none; }
	.has-sub.active > ul { display: block; }
}
@media (min-width: 47.9375em) {	
	.leftnav { display:block!important;}
}
@media (max-width: 47.9375em) {	
	.nav-icon {
		width: 1.563rem;
		height: 1.563rem;
		position: relative;
		cursor: pointer;
		margin: 0.313rem 0 0 0.938rem;		
		span {
			display: block;
			position: absolute;
			height: 0.188rem;
			width: 100%;
			background: $color-green;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;

			&:nth-child(1) { top: 0; }
			&:nth-child(2), &:nth-child(3) { top: 0.500rem; }
			&:nth-child(4) { top: 1.000rem; }
		}
		&.open {
			span {
			&:nth-child(1) { top: 1.000rem; width: 0%; left: 50%; }
			&:nth-child(2) { -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); }
			&:nth-child(3) { -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg); }
			&:nth-child(4) { top: 1.000rem; width: 0%; left: 50%; }
			}
		}
	}
	aside.leftnav { display: none; }
	.leftnav { 
		background-color: $color-white;
		display:none;
		height: 100%;
		padding: 0;
		position: absolute;
		left: 0; 
		top: 3.813rem;
		z-index: 100;
		.has-sub {
			&::after { right: 0.375rem; }
			&.active::after { right: 0.438rem; }
		}
		nav { padding: 0; 
			a { padding: 0 0.938rem; }
			.show-for-small-only { border-bottom: 1px solid $color-border; } 
			ul ul { 
			border-top: 1px solid $color-border; 
			margin-bottom: -1px;
				a { border-bottom: 1px solid $color-border; }
			}
		}
	}
}