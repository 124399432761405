$namespace: "ps";

.#{$namespace} {
  &-form__btn {
    &--margin {
      margin-top: 1.25rem !important;
    }
    &--startpage {
      margin-top: 24px;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }
}

// restyle cookie button
.cc-btn {
  background-color: $color-baywagreen;
  color: $color-baywawhite;
  transition: $button-transition;
  width: 100%;
  &:hover {
    background-color: $color-baywagreenhover;
    color: $color-baywawhite;
  }
}
