$namespace: "ps";

.#{$namespace}-newShippingAddress-form {
  &_buttons {
    margin-top: 1rem;
  }

  &_requiredFieldsInfo {
    float: right;
  }

  .noSuitableAddressInfo {
    background-color: $color-baywayellow;
    color: $color-baywawhite;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    padding: 15px 10px;
  }
}