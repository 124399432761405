$namespace: "ps";

.#{$namespace}-addresssuggestion {
  &__row {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  &__radio {

    &:not(:first-child) {
      margin-top: 2rem;
    }

    &-input {
      &-label{
        vertical-align: top!important;

        &-title {
          font-weight: bold;
          margin-bottom: 0;
        }

        &-content {
          margin-bottom: 0;

          &-changed {
            font-weight: bold;
            color: $color-green;
          }
        }
      }
    }
  }
}