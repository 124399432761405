body div h1, .h1 {

	color: $color-baywagreen;
	font-size: 1.75rem;
	margin-bottom: 1.8rem;

	&.big {
			line-height: 3.5rem;
			in-bottom: 0;

			@include breakpoint(small only) {
			  font-size: 1.75rem;
				line-height: 2.5rem;
			}
			}

	&.grey { color: $color-baywadarkgrey; }
	&.white { color: $color-baywawhite; }
}

body div h2, .h2 {
	font-family: "BayWaSans", Arial, sans-serif!important;
	color: $color-baywagreen;
	font-size: 2rem!important;
	margin-bottom: 1rem!important;
	line-height: 2.5rem!important;

	&.big {
			font-size: 2.5rem;
			line-height: 3.5rem;
			margin-bottom: 0;

			@include breakpoint(small only) {
			  font-size: 1.75rem;
				line-height: 2.5rem;
			}
		}

	&.grey { color: $color-baywadarkgrey!important; }
	&.white { color: $color-baywawhite!important; }

	&.teaser { font-weight: 600; }
	}

body div h3, .h3 {

	color: $color-baywagreen;
	font-size: 1.5rem!important;
	margin-bottom: 1rem!important;

	&.grey { color: $color-baywadarkgrey; }
	&.white { color: $color-baywawhite; }
}

.small-text {
	font-size: 0.875rem !important;
}	

.inline {
	display: inline !important;
}	

// general margins

.margin-bottom-30px {
	margin-bottom: 30px;
}

.margin-bottom-24px {
	margin-bottom: 24px;
}

.margin-bottom-20px {
	margin-bottom: 20px!important;
}

.margin-bottom-8x {
	margin-bottom: 8px!important;
}

.margin-top-0px {
	margin-top: 0!important;
}

.margin-top--3px {
	margin-top: -3px;
}

.margin-top-20px {
	margin-top: 20px;
}

.margin-top-77px {
	margin-top: 77px!important;
}

.margin-float-left {
	margin: 0 20px 10px 10px;
}

@include breakpoint(medium only) {
	.medium-margin-top-2rem {
		margin-top: 16px;
	}
}

.padding-0px {
	padding: 0;
}

.padding-top-0px {
	padding-top: 0 !important;
}


.padding-top-5px {
	padding-top: 5px !important;
}

.padding-side-24px {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.padding-top-bottom-16px {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.border-bottom-light-grey {
	border-bottom: solid 1px #ccc;
}

/*a[href^="tel:"] {
  color: $color-baywadarkgrey;
}

a[href^="tel:"]:hover  {
  color: $color-baywagreen;
}*/

a {
	font-weight: 600;
}

a.white {color:$color-baywawhite; font-weight: bold;}
a.white:hover {text-decoration: underline;}

.padding-left-64tpx {
	padding-left: 4rem;
}

.height100 {
	height: 100%;
}

.height-auto {
	height: auto;
}

.width100 {
	width: 100%;
}

// neue startseite

.bw-video-teaser {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;
	width: 100%;

	@include breakpoint(medium) {
		width: 83.33333%;
	}

	@include breakpoint(large) {
		width: 66.66667%;
	}

	&__image {
		width: 100%;
		max-height: 100%;
	}

	&__overlay {
		align-items: center;
		-webkit-backdrop-filter: blur(2px);
		backdrop-filter: blur(2px);
		// background-color: rgba(0, 0, 0, 0.2);
		bottom: 50px;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		padding: 10px 0 10px 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&__header {
		padding-bottom: 30px;
		color: #333;
		align-self: flex-start;
	}

	&__subline {
		display: none;
		padding-top: 20px;
		width: 100%;
	}

	&__content {
		align-items: center;
		color: #fff;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		justify-content: flex-start;
		margin-bottom: 20px;
		overflow: hidden;
		position: relative;
		width: 100%;
		z-index: 0;
	}

	&__play {
		flex: 1;
		width: 100%;
	}

	&__disclaimer {
		justify-self: flex-end;
		font-size: rem-calc(16px);
		text-align: center;
	}

	&__disclaimer-link, &__disclaimer-link:visited, &__disclaimer-link:active {
		color: $color-baywawhite;
		font-style: normal;
		text-decoration: underline;

		&:hover, &:focus, &:focus-visible, &:focus-within {
			color: $color-baywawhite;
			font-weight: 600;
		}
	}
}

@media print, screen and (min-width: 43.5em) {
	.bw-video-teaser__overlay {
		padding: 50px 50px 20px;
	}
}

@media screen and (min-width: 61.5em) {
	.bw-video-teaser__header {
		padding-bottom: 40px;
	}
}

@media print, screen and (min-width: 43.5em) {
	.bw-video-teaser__subline {
		display: block;
	}
}

.icon-play-button {
	background: url("../../images/video-play-button.svg");
	background-position-x: center;
	background-position-y: center;
	background-size: 50px;
	background-repeat: no-repeat;
}

.bw-img-teaser {
	width: 100%;
}

.bw-bg-green {
	background-color: $color-baywagreen!important;
}

.bw-color-white {
	color: white!important;
}

.link {
	color: #fff;
	text-decoration: none;
	display: inline;
	position: relative;
	font-weight: 600;
	font-style: normal;

	&:hover {
		text-decoration: underline;
	}
}

.link-icon-phone {
	background-origin: padding-box;
	padding-left: 32px;
	background: url("../../images/077_Pikto_Telefon_weiss_V2.svg");
	background-position-y: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 32px;
	display: inline-block;
	line-height: 34px;
	@include breakpoint(small only) {
		margin-left: 6px;
		margin-bottom: 20px;
		padding-left: 38px;
	}
}

.link-icon-e-mail {
	background-origin: padding-box;
	padding-left: 44px;
	background: url("../../images/121_Pikto_MeineNachrichten_weiss_V2.svg");
	background-position-y: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 22px;
	display: inline-block;
	line-height: 24px;
}

.charity-banner {
	background: $color-baywagreen;
	display: block;
	float: left;
	margin-bottom: 25px;
	width: 100%;

	&__img {
		background: center / cover no-repeat;
		background-image: url(../../images/baywa_stifung_gross.jpg);
		height: 250px;
		@include breakpoint(large) {
			height: 100%;
		}
	}

	&__logo {
		margin-bottom: 30px;
		width: 75px;
	}

	&__brandbox-content {
		padding: 15px;
		@include breakpoint(large) {
			padding: 15px 15px 15px 0;
		}
	}
}

.aktions-banner {
	background: $color-baywagreen;
	display: block;
	float: left;
	margin-bottom: 25px;
	width: 100%;

	&__img {
		background: center / cover no-repeat;
		background-image: url(../../images/vitaholz_zweitesleben_start.jpg);
		height: 250px;
		@include breakpoint(large) {
			height: 100%;
		}
	}
}

