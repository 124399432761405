@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Book.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Book.ttf') format('truetype'); /* for super Modern Browsers */
}

@font-face {
  font-family: 'BayWa Sans Book'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Book.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Book.ttf') format('truetype'); /* for super Modern Browsers */
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Medium.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Medium.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 300;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-MediumItalic.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-MediumItalic.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Medium.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Medium.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 500;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-MediumItalic.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-MediumItalic.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-SemiBold.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-SemiBold.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 600;
}

@font-face {
  font-family: 'BayWa Sans SemiBold'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-SemiBold.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-SemiBold.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 600;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-SemiBoldItalic.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-SemiBoldItalic.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Bold.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-Bold.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: bold;
}

@font-face {
  font-family: 'BayWa Sans'; /* BayWa own Font */
  font-display: swap;
  src: url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-BookItalic.woff2') format('woff2'),
  url('https://res.cloudinary.com/baywa-ag/raw/upload/pattern-library/fonts/latest/baywa_sans/BayWaSans-BookItalic.ttf') format('truetype'); /* for super Modern Browsers */
  font-weight: bold;
  font-style: italic;
}
