$namespace: "ps";

.#{$namespace}-checkout {

  &-agb {
    margin-bottom: 16px;
  }

  &-addressDataPageCart {
    &__row {
      padding-top: 20px;
      padding-bottom: 20px;

      &:first-child {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-grey;
      }

      &-value {
        text-align: right;
      }
    }

    &-sum {
      color: $color-green;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0;

      @include breakpoint(small only) {
			  font-size: 1.25rem;
			}
    }

    &-tax {
      font-size: .875rem;
      margin-bottom: 0;
    }

    &__info {
      font-size: rem-calc(14);

      &-text {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          margin-top: -1rem;
          left: 9.5rem;
          border-width: 0.500rem;
          border-style: solid;
          border-color: transparent transparent #333 transparent;
        }

        text-align: left;
        left: 0;
        position: absolute;
        background-color: #333;
        color: #fff;
        font-weight: 500;
        font-size: .875rem;
        margin-top: -.5rem;
        padding: .938rem .625rem;
        width: 100%;
        z-index: 99;

      }
    }
  }
}
