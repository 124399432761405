// includes all individual colors for BOWN

$color-white : #FFFFFF;
$color-red : #D7373C;
$color-green : #008C46;
$color-hover-green : #006F3B;
$color-lightgreen : #CDDC00;
$color-body : #333333;
$color-lightgrey : #F4F4F4;
$color-clear : #E8E8E8;
$color-border : #ECECEC;
$color-middlegrey : #E5E5E5;
$color-form : #CCC;
$color-silver : #CCC;
$color-gallery : #ECECEC;
$color-darkgrey : #808080;
$color-boxshadow: #AAA;
$color-alertred: #FEF3F4;
$color-alertgreen: #F2FAF6;
$color-boxshadow-light: rgba(0,0,0,0.24);
$color-black : #4D4D4D;
$color-gold : #FFCC00;
$color-grey :  #CCC;
$warning-color: get-color(warning);

$color-riogrande: #CDDC00;
$color-mercurysolid: #E5E5E5;
$color-gray: #808080;
$color-tundora: #4D4D4D;
$color-alabaster: #F7F7F7;

// utility colors
$color-crimson : #EA1829;
$color-wisppink: #FEF3F4;
$color-panache: #F2FAF6;

// legacy colors no longer in the Pattern Library
$color-baywagrey4: #666666;
$color-baywa-silver-grey: #999;
