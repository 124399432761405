.agent-login {
	color: $color-white;
	background-color: $color-red;
	display: inline-block;
	font-weight: bold;
	padding: 1.563rem 0;
	text-align: center;
	width: 100%;
}
$head-width: 790px;
@mixin loginname {
  @media (max-width: #{$head-width}) {
    @content;
  }
}

header {
	background: $color-white;
	box-shadow: 0 0 5px #333;
	margin: 0 0 1.25rem 0;
	#search-field { margin: 1.250rem 0 0 0; width: 21.250rem; }
	.meta-nav {
		background: $color-body;
		ul {
			margin: 0;
			float: right;
			li {
				float: left;
				height: 2.188rem;
				line-height: 2.188rem;
				list-style: outside none none;
				margin: 0 0 0 1.875rem;
				padding: 0;
				a {
					color: $color-white;
					font-size: 0.875rem;
					font-weight: bold;
				}
			}
		}
	}
	.btn {
		background-color: $color-green;
		color: $color-white;
		display: block;
		float: left;
		font-size: 1.125rem;
		height: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
		width: 100%;
		&:hover { background-color: $color-darkgrey; }
	}
	/* Placeholder color */
	*::-webkit-input-placeholder {
		color: $color-body;
	}
	*:-moz-placeholder {
		/* FF 4-18 */
		color: $color-body;
	}
	*::-moz-placeholder {
		/* FF 19+ */
		color: $color-body;
	}
	*:-ms-input-placeholder {
		/* IE 10+ */
		color: $color-body;
	}
	.header {
		padding: 2.1875rem 0.625rem 0 0.625rem;
	  height: 6.25rem;
		.columns { margin: 0; width: auto; padding: 0; }
	}
	.baywa-logo {
		display: inline-block;
		width: 102px;
	}
	.claim { font-size: 1.25rem; margin: 0; height: 2rem; padding: 0 0 0 2.75rem; line-height: 2.3rem;}
	.my-account, .header-cart {
		font-weight: 500;
		margin: -0.6rem 0 0 0;
		position: relative;
		font-size: 0.875rem;
		line-height: 1.059rem;
		.ico-bw {
			color: #808080;
			float: left;
			font-size: 1.5rem;
			margin: 0 0.563rem 0 0;
			padding-left: 1.1rem;
		}
		.head-txt, #header_name { color: $color-body; }
		.head-txt, .login-txt, .cart-price { clear: both; float: left; }
		.login-txt { color: $color-green; position: relative; }
	}
	.my-account {
		padding-bottom: 1.875rem;
		cursor: pointer;
		margin-left: 0;
		.login-txt > span { float: left; }
		.icon-sort_down { display: block; font-size: 80%; margin: 0.188rem 0 0 0.188rem; }
		.login-name-txt { float: left; }
		#header_name {
		    text-overflow: ellipsis;
		    max-width: 8.125rem;
		    overflow: hidden;
		    white-space: nowrap;
		    position: relative;
		    display: block;
		    float: left;
		    margin: 0 0 0 0.250rem;

		    @include loginname { margin: 0; clear: both; }
		}
	}
	.header-cart {
		position: relative;
		.cart-counter {
			background-color: $color-red;
			border-radius: 1rem;
			color: $color-white;
			font-size: 0.750rem;
			font-weight: 500;
			min-width: 1.250rem;
			min-height: 1.250rem;
			position: absolute;
			border: 2px solid $color-white;
			text-align: center;
			background-color: $color-red;
			left: 2.5rem;
			top: -0.250rem;
			padding: 0 0.188rem;
		}
	}
	nav {
		ul, li {
			list-style: outside none none;
			line-height: 2rem;
			margin: 0;
			padding: 0;
		}
		li { float: left; margin: 1.25rem 3.125rem 1.25rem 0; }
	}
	.myaccount-box {
		background-color: $color-white;
		box-shadow: -1px -1px 10px -2px $color-boxshadow;
		display: none;
		left: -4.813rem;
		padding: 1.063rem 1.063rem 0.125rem 1.063rem;
		position: absolute;
		top: 1.875rem;
		width: 14.375rem;
		z-index: 1;
		.triangle-with-shadow {
			box-shadow: 0 16px 10px -17px $color-boxshadow;
			height: 13px;
			overflow: hidden;
			position: absolute;
			width: 30px;
			top: -13px;
			left: 42%;
		}
		.triangle-with-shadow:after {
			background: $color-white none repeat scroll 0 0;
			box-shadow: -1px -1px 10px -2px $color-boxshadow;
			content: "";
			height: 15px;
			left: 6px;
			position: absolute;
			top: 6px;
			transform: rotate(45deg);
			width: 16px;
		}
		.register-now {
			display: inline-block;
			margin: 0.75rem 0;
			width: 100%;
			p {
				color: $color-body;
				float: left;
				font-size: 0.875rem;
				margin: 0;
			}
			a {
				color: $color-body;
				float: right;
				font-weight: normal;
				text-decoration: underline;
				&:hover { color: $color-green; }
			}
		}
		ul {
			border-top: 0.125rem solid $color-middlegrey;
			display: block;
			float: left;
			margin: 0;
			padding: 0.875rem 0 0;
			width: 100%;
			li {
				clear: both;
				float: left;
				list-style: outside none none;
				margin: 0 0 0.750rem;
				padding: 0;
				width: 100%;
				a {
					color: $color-body;
					font-size: 1.000rem;
					font-weight: normal;
					&:hover { color: $color-green; }
				}
			}
		}
		&.logout-hover {
			ul {
				border-top: none;
				border-bottom: 0.125rem solid $color-middlegrey;
				margin: 0 0 0.938rem;
				padding: 0.313rem 0 0 0;
			}
			.btn { margin: 0 0 0.938rem; }
		}
	}
	.navigation-overlay {
		background-color: rgba(0,0,0,.8);
		height: 100%;
		z-index: -1;
		position: fixed;
		width: 100%;
		opacity: 1;
		transition: opacity .3s;
		display: none;
	}

	a.claim-a:link {color:$color-baywagreen;}
	a.claim-a:visited {color:$color-baywagreen;}
	a.claim-a:hover {color:$color-baywagreen;}
}

// viewport >1162px
@media (max-width: 1162px ) {
	header .header {
		// padding: 1.6rem .9375rem 0 .9375rem;
	}
}

// medium viewport
@media (max-width: 999px) {
	header .header {
		.columns { margin: 0; width: auto; }
		#search-field { width: 19.063rem; }
	}
}
// small viewport
// Breakpoint up to 767px
@media (max-width: 767px) {
	header .header {

		padding: 1rem .9375rem 0 .9375rem;
		height: 3.125rem;

		.baywa-logo {
			width: 70px;
		}
		.claim {
			font-size: 1.2rem;
			margin: 0;
			padding-left: 15px;
			padding-top: 5px;
			line-height: 16px;
		}
		.columns { width: auto; }
		#search-field {
			float: right;
			width: auto;
			margin: 0;
			span {
				top: 0;
				right: 0.125rem;
				font-size: 1.563rem;
					&:hover { color: $color-body; }
			}
		}
		.my-account { width: auto; }
		.my-account, .header-cart {
			margin: 0 0 0 0.875rem;
			padding: 0;
			font-size: 0.625rem;
			line-height: 0.7rem;
			.ico-bw {
				margin: -0.5rem 0 0 0;
				padding-left: 0.8rem;
				font-size: 1.5rem;
					&.icon-user {
						font-size: 1.563rem;
					}
/* 			.head-txt { line-height: 0.7rem; } */
			}
			&.icon-basket, &.icon-collect { font-size: 1.5rem; }
		}
		.cart-counter {
			line-height: 1rem;
			left: 1.8rem;
			top: -0.8rem;
		}
	}
}
