$namespace: "ps";

.#{$namespace}-cookieInfo {
    background: $color-white;
    color: $color-body;
    box-shadow: 0 0 5px #999;

    padding: 1rem 0;
    width: 100%;
    -ms-flex-direction: row;
    flex-direction: row;

    -ms-flex-align: center;
    align-items: center;

    left: 0;
    right: 0;
    bottom: 0;

    opacity: 1;

    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.3em;
    z-index: 9999;

  &_invisible {
    opacity: 0;
  }

  &_message {
    flex: 1;
    padding-bottom: 1rem;

    &_link {
      opacity: .8;
      display: inline-block;
      text-decoration: underline;
      cursor: pointer;
    }
    
    @include breakpoint(small only) {
      font-size: 14px !important;
    }
  }

  &_compliance {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: justify;
    align-content: space-between;

    &_button {
      display: block;
      font-size: .9em;
      font-weight: 700;
      border-width: 2px;
      border-style: solid;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      background-color: #008c46;
      color: #fff;
      width: 100%;
      -ms-flex: 1;
      flex: 1;

      &:last-child{
        min-width: 140px;
      }
    }
    
    &_form {
      width: 100%;
    }
  }
}