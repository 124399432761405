.load-adresses {
  width: 100%;
}

.delivery_form_input, .invoice_form_input {
  position: relative;
}

.delivery-address, .invoice-address {
  border: 1px solid $color-mercurysolid;
  margin: 0 0 1.938rem 0;
  padding: 0 1rem 1.25rem;
  a {
    color: $color-tundora;
  }
  h6 {
    background: $color-alabaster;
    font-size: 1.125rem;
    font-weight: bold;
    height: 2.500rem;
    line-height: 2.500rem;
    margin: 0 -1rem 1.25rem;
    padding: 0 3rem 0 1rem;
    position: relative;
    cursor: pointer;
    &::after {
      display: block;
      position: absolute;
      right: 0.938rem;
      top: 0;
      font-family: 'icomoon';
      font-weight: normal;
      color: $color-gray;
      content: "\e90c";
    }
  }
  .delivery-address-name, .invoice-address-name {
    font-weight: bold;
  }
  &.standard-address {
    h6 {
      background: $color-riogrande;
      color: $color-white;
      &::after {
        color: $color-white;
      }
    }
  }
  &.add-new-address {
    border: 1px dashed $color-mercurysolid;
  }
  &.teaser.new-widget {
    padding: 2.500rem 0 2.5rem;
    p {
      margin: 1.75rem 0 0;
    }
  }
}

.addresses_navigation-buttons {
  margin-top: -1.563rem !important;
}

.personal-data__date-of-birth {
  label {
    display: inline-block;
  }
  input {
    text-align: center;
  }
  .info__text {
    top: 2.938rem;
  }
  @media (max-width: 767px) {
    .info__text {
      top: 2.250rem;
    }
  }

  // info
  .information-text, .info__text {
    position: absolute;
    color: $color-baywawhite;
    background-color: $color-baywadarkgrey;
    font-weight: 500;
    margin-top: -.188rem;
    padding: .938rem .625rem;
    width: 100%;
    z-index: 10;
    font-size: .875rem;
    line-height: normal;
    &:after {
      content: "";
      position: absolute;
      bottom: 100%;
      right: .813rem;
      border-width: .5rem;
      border-style: solid;
      border-color: transparent transparent #333;
    }
  }
  .info__text {
    &:after {
      right: auto;
      left: 7.188rem;
    }
  }
  .info__text {
    &:after {
      right: auto;
      left: 7.188rem;
    }
  }
  .info {
    &__trigger, &__content {
      float: left;
    }
    &__trigger {
      color: $color-green;
      font-size: 1.25rem;
      position: absolute;
      padding: 0 0 0 0.313rem;
      cursor: pointer;
      width: 1.875rem;
      height: 1.563rem;
    }
    &__text {
      margin-top: 0;
      left: 0;
    }
  }
}