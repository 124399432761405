step
.bw-list {
    list-style: inside;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-left: 1.25rem;
    padding-left: 0.5rem;
}

.bw-list-bullets {
    list-style: inside;
    list-style-type: square;
    line-height: 1rem;
    padding-bottom: 1rem;

    span {
      position: relative;
      left: 0rem;
      @include breakpoint(small only) {
          line-height: 1.5rem;
    	}
    }
}

.bw-teaser-basic {
    margin-bottom: 3rem;
    height: auto;
    float: left;
    width: 100%;

    @include breakpoint(small only) {
      margin-bottom: 1rem;
  	}
}

.bw-teaser-top01 {
    height: 500px;

    @include breakpoint(medium only) {
      height: 370px;
    }

    @include breakpoint(small only) {
      height: 830px;
  	}
}

.bw-teaser-pellets {

@include breakpoint(large) {
   height: 410px;
   margin-bottom: 4rem;
 }
}

.bw-teaser-certificate {
  min-height: 0;

  @include breakpoint(medium) {
    height: 660px;
  }
  @include breakpoint(large) {
    height: 550px;
  }
}

.bw-teaser-pellets-p {
    margin-bottom: 0;

	@include breakpoint(small only) {
        padding-top: 20px;
  	}
}

.step-illustration {
    text-align: center;
    margin: 2rem 0 4rem 0;
    @include breakpoint(small only) {
      margin-bottom: 2rem;
  	}
}

.hints {
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    display: block;
}

.hints .step-1, .hints .step-2, .hints .step-3 {
    width: 25%;
}

.hints>* {
    float: left;
    line-height: 1.125rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;

    @include breakpoint(small only) {
      font-size: 0.75rem;
  	}
}

.icon-step {
    color: $color-baywawhite;
    background-color: $color-baywagreen;
    font-size: 4rem;
    padding: 1rem;

    @include breakpoint(small only) {
      font-size: 2.5rem;
      padding: 0.6rem;
    }
}

.icon-next-step {
    color: $color-baywagrey1;
    font-size: 2rem;
    margin-top: 2rem;
    width: 10%;

    @include breakpoint(small only) {
      font-size: 1.3rem;
      margin-top: 1rem;
  	}
}

.icon-phone-start {
    color: $color-baywagreen;
    background-color: transparent;
    font-size: 2rem;
    position: absolute;
    left: -0.4rem;
    top: -0.43rem;
}

.p-phone {
  padding-left: 2rem;
  position: relative;
}

.icon-globe {
  height: 30px;
  padding-right: 11px;
}

.icon-mail {
  height: 42px;
  padding-right: 10px;

  @include breakpoint(small only) {
    height: 30px;
    padding-right: 12px;
  }
}
