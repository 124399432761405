$namespace: "bw";
.#{$namespace} {
  &-alertbox {
    display: block;
    float: left;
    font-family: 'BayWa Sans SemiBold', sans-serif;
    margin: 0 0 1.250rem 0;
    padding: 0.938rem 0.938rem 0.938rem 3.750rem;
    position: relative;
    width: 100%;
    color: $color-baywawhite;
    @include breakpoint( small only ) { font-size: 0.875rem; }
    &--ok {
      background-color: $color-baywagreen;
    }
    &--error {
      background-color: $color-baywayellow;
      .row {
        margin-bottom: 1.250rem;
        .bw-alertbox {
          margin: 0;
          padding: 0.750rem 0.938rem 0.688rem 3.125rem;
          a {
            color: $color-baywawhite;
            font-size: 1rem;
            line-height: inherit;
            text-decoration: underline;
            @include breakpoint( small only ) { font-size: 0.875rem; }
          }
          .icon {
            left: 0.938rem;
          }
          &.conceal {
            padding: 0.750rem 2.500rem 0.688rem 3.125rem;
            span { padding: 0 0.188rem 0 0; }
            .icon {
              font-size: 1.250rem;
              padding: 0;
              &-remove {
                cursor: pointer;
                left: inherit;
                right: 0.938rem;
              }
            }
          }
        }
      }
      &--critical {
        background-color: $color-baywared;
      }
    }
    &--agent {
      background-color: $color-baywaorange;
      margin: 0;
      float: none;
      z-index: 4;

      .bw-alertbox__message {
        text-align: center;
      }
    }
    &-list {
      display: inline-block;
      width: 100%;
      .bw-alertbox {
        margin: 0;
      }
      .bw-alertlist {
        color: $color-baywawhite;
        font-family: 'BayWa Sans SemiBold', sans-serif;
        padding-left: 3.750rem;
        margin: 0.313rem 0 0;
        ul, li {
          list-style: inside none disc;
          @include breakpoint(small only) { font-size: 0.875rem; }
        }
        ul {
          margin: 0 0 1rem 0;
        }
      }
    }
    .icon {
      font-size: 1.563rem;
      left: 1.250rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.icon-wetterwarnung {
        font-size: 1.7rem;
      }
    }
    span {
      float: left;
    }
  }
}