.loader {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
  margin: -2.5em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
	color: $color-green;
	font-size: 10px;
	position: absolute;
	left: 50%;
	top: 50%;
	text-indent: -9999em;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	z-index: 100;
	&:before,
	&:after { 
		content: ''; 
		position: absolute; 
		top: 0; 
		border-radius: 50%;
		width: 2.5em;
		height: 2.5em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: load7 1.8s infinite ease-in-out;
		animation: load7 1.8s infinite ease-in-out;
	}
	&:before { left: -3.5em; -webkit-animation-delay: -0.32s; animation-delay: -0.32s; }
	&:after { left: 3.5em; }
	@-webkit-keyframes load7 {
  	0%,
  	80%,
  	100% { box-shadow: 0 2.5em 0 -1.3em; }
  	40% { box-shadow: 0 2.5em 0 0; }
	}
	@keyframes load7 {
  	0%,
 	80%,
  	100% { box-shadow: 0 2.5em 0 -1.3em; }
  	40% { box-shadow: 0 2.5em 0 0; }
	}
}

.overlay {
  opacity: 0.4;
  filter: alpha(opacity=40);
  background-color: $color-grey;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

