$namespace: "ps";

.#{$namespace}-cart {

  &-header {
    font-size: 40px;
    font-weight: 300;
    line-height: 45px;
    margin-bottom: 1.25rem;
  }

  &-items {
    &__itemRow {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &__item {
      position: relative;
      background-color: $color-white;
      padding: rem-calc(25) rem-calc(15) rem-calc(25) rem-calc(15);
      margin-bottom: rem-calc(20);

      &__quantity {

        &-value {
          font-size: rem-calc(20);
          font-weight: bold;
          margin-bottom: 0;
        }

        &-title {
          font-size: rem-calc(14);
          font-weight: normal;
          margin-bottom: 0;
        }
      }

      &__postalcode {

        @include breakpoint(medium) {
					position: absolute;
          bottom: 0;
          left: 0;
        }

        @include breakpoint(small only) {
          &-container {
            float: right;
          }
        }

        &-value {
          font-size: rem-calc(20);
          font-weight: bold;
          margin-bottom: 0;
        }

        &-title {
          font-size: rem-calc(14);
          font-weight: normal;
          margin-bottom: 0;
        }
      }

      &__services {
        @include breakpoint(small only) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }


        &-title {
          font-size: rem-calc(20);
          font-weight: bold;
          margin-bottom: 0;
        }

        &-service {
          font-size: rem-calc(14);
          font-weight: normal;
          margin-bottom: 0;
          line-height: 1.5rem;
        }
      }

      &__part1 {
        position: relative;
        min-height: 5.75rem;
      }

      &__part3 {
        position: relative;
      }

      &__delete {
        text-align: right;

        @include breakpoint(small only) {
          text-align: center;
          padding-top: 1.25rem;
        }
      }

      &__imageContainer {
        height: 300px;
        position: relative;
      }

      &__image {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &__link {
        font-size: rem-calc(14);
        color: $color-baywagreen;
        text-decoration: $color-baywagreen underline;
      }

      &__price {
        $root: &;

        &--small {
          font-size: rem-calc(18);
          @include breakpoint(small only) {
            #{$root}__unit {
              float: left;
              margin-right: rem-calc(10);
              margin-top: rem-calc(-3);
            }
          }
        }

        &--big {
          text-align: center;
          font-size: rem-calc(20);

          @include breakpoint(medium up) {
            text-align: right;
            position: absolute;
            bottom: 0;
            right: .625rem;
          }
        }

        &__unit {

          &_right {
            font-weight: bold;
            float: right;
          }

          &_previous {
            font-weight: bold;
            font-size: rem-calc(14);
            color: $color-baywagrey4;
            text-decoration-line: line-through;
            text-decoration-color: $color-baywared;
          }

          &_big {
            font-weight: bold;
            font-size: rem-calc(20);
          }
        }

        &__info {
          font-size: rem-calc(14);

          .ggv-info-toggle {
            color: $color-green;
            text-decoration: underline;
          }

          &-right {
            float: right;
            font-size: rem-calc(14);
          }

          &-text {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              margin-top: -1rem;
              right: 4rem;
              border-width: 0.500rem;
              border-style: solid;
              border-color: transparent transparent #333 transparent;

              @include breakpoint(small only) {
                right: 50%;
              }
            }

            text-align: left;
            position: absolute;
            left: 0;
            background-color: #333;
            color: #fff;
            font-weight: 500;
            font-size: .875rem;
            margin-top: 1rem;
            padding: .938rem .625rem;
            width: 100%;
            z-index: 10;

            @include breakpoint(small only) {

            }
          }
        }
      }
    }

    &__buttonSpacer {
      margin-bottom: 1rem;
    }
  }

  &-continueshopping {
    margin-bottom: 1.25rem;
  }

  &-price-warranty {
    width: rem-calc(160);
    font-weight: 600;
    color: $color-baywawhite;
    padding: 1rem;
    background-color: $color-baywagrey1;
    text-align: center;
    margin-top: -1.57rem;
    @include breakpoint(small only) {
      width: rem-calc(90);
      word-break: break-word;
      padding: rem-calc(12);
    }

  }

  &-empty {
    width: 100%;
    background-color: $color-white;
    height: rem-calc(400px);

    &__messageRow {
      padding-top: 5rem;
    }

    &__buttonRow {
      padding-top: 2rem;
    }
  }

  &-orderoverview {
    background-color: $color-white;
    padding: rem-calc(25) rem-calc(15) rem-calc(25) rem-calc(15);
    margin-bottom: rem-calc(20);

    &__heading {

    }
    &__check-box{
      @include breakpoint(small only) {
      	padding-left: 19px;
      	padding-right: 19px;
      }
      padding-left: 11px;
      padding-right: 11px;
      &-text{
        padding-left: 3px;
        position: relative;
    	max-width: 95%;
        margin: -9px 0px 15px 0px !important;
      }
      &-icon{
      	min-width: 3%;
      }
    }
    &__ggv-info-toggle {
      color: $color-green;
      text-decoration: underline;
    }
  }
}
