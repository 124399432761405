$namespace: "ps";

.#{$namespace}-hero-teaser {
  &__img {
    width: 100%;
    object-fit: contain;
    margin-top: -1.5rem;
    z-index: -5;

    @include breakpoint(small only) {
      padding: 0;
      height: 100%;
      width: auto;
      overflow-x: hidden;
      direction: rtl;
      img {
        max-width: none;
        height: 400px;
      }
    }
  }

  &__pricing-form-side {
    background-color: transparent;
  }

  &__pricing-form {
    background-color: $color-baywawhite;
    z-index: 1;
    padding-top: 24px ;
    margin-top: -192px;
    margin-bottom: 54px;
    padding-bottom: 24px;
    display: flex;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
    @include breakpoint(medium only) {
      margin-top: 0;
    }
    @include breakpoint(small only) {
      margin-top: -1.5rem;
      padding-top: 1rem;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 1.5rem;
      z-index: 0;
    }

    &__input {
      width: 100% !important;
      height: 2.813rem !important;
      padding: 0 0.625rem !important;
      line-height: 2.813rem !important;
      margin-bottom: rem-calc(10) !important;

      &__with-error, &__with-error:focus {
        border-color: $color-red;
        color: $color-red;
      }
    }

    &__form-group {
      position: relative;
    }

    &__input-icon {
      color: $color-red;
      position: absolute;
      top: 0;
      right: .625rem;
      z-index: 2;
      display: block;
      width: 2.813rem!important;
      height: 2.813rem!important;
      line-height: 2.813rem!important;
      text-align: center;
      pointer-events: none;
    }

    &__input-error {
      font-size: 0.875rem;
      color: $color-red;
    }
  }

}


.full-width {
  max-width: 100%!important;
  display: block;
  margin-top: -1.5rem;
  margin-bottom: 3rem;
}

.teaser-component {

  height: 400px;
  box-sizing: border-box;
  display: flex;

  &__image {
    // padding-right: 0;
    display: block;
    position: relative;
    width: calc(50% + 190px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $color-baywagreen;
    color: $color-baywawhite;
    width: calc(50% - 190px);
    padding-top: 40px;
    padding-right: calc(50% - 600px);
    padding-bottom: 40px;
    padding-left: 40px;
  }

}

.image-wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
    display: block;

    img
      {
          transform: translate(0%, -50%);
          position: absolute;
          top: 50%;
          // left: 50%;
          max-width: 100%;
          // max-height: none;
          // z-index: 1;
          // transform: translate(-50%, -50%);
          // opacity: 1;
          // transition: opacity 0.5s linear;
          // width: 100%;
          // vertical-align: middle;
          // text-indent: -9999px;
          // color: transparent;
      }
}

@media screen and (max-width: 47.9375em) {
  header {
    margin: 0;
  }

  .ps-hero-teaser__pricing-form__form-group {
    width: 47%;
    padding: 0;
    margin: 0;
  }

  input[type="submit"],
  .ps-form__btn--startpage {
    margin-top: 0;
  }
}
