/*
this file is just used for current addresses page, when the new design implemented this file have to be removed
 */

.contract-order-overview {
  .form-group {
    &.ico-x.update:after {
      content: "\e93e";
      color: $color-green;
      font-size: 1.313rem;
    }
  }
}

//alerts
.alert-box {
  background-color: $color-wisppink;
  border: 1px solid $color-crimson;
  border-radius: 0.188rem;
  color: $color-crimson;
  display: block;
  float: left;
  font-size: 1.000rem !important;
  margin: 0 0 1.250rem 0;
  padding: 0.500rem 1.125rem 0.375rem;
  width: 100%;
  span {
    float: left;
  }
  span:last-child {
    max-width: 80%;
  }
  .icon {
    padding: 0.188rem 0.375rem 0 0;
  }
  &.alert-ok {
    background-color: $color-panache;
    border-color: $color-green;
    color: $color-green;
  }
}

.with-alert {
  .white-box {
    padding: 0 !important;
  }
  .white-box-content {
    padding: 1.25rem;
    display: inline-block;
    position: relative;
    width: 100%;
  }
}

.alert {
  color: $color-green;
  &.alert-danger {
    color: $color-crimson;
    font-size: 0.875rem;
    margin: -0.625rem 0 1rem 0;
  }
}

input, select, textarea {
  &.alert-danger {
    color: $color-crimson;
    border-color: $color-crimson;
  }
}

input, button, a.btn {
  &.btn-cancel {
    border: 1px solid $color-green;
    background: $color-white;
    color: $color-green !important;
  }
}

[type="submit"]:active {
  padding: 0;
}

[type="submit"]:hover, [type="button"]:hover {
  background: $color-hover-green;
}

a.btn.btn-cancel:hover,
.btn-cancel[type="submit"]:hover,
.btn-cancel[type="button"]:hover {
  color: $color-hover-green !important;
  border-color: $color-hover-green;
  background: $color-white;
}

input[type=submit]:disabled {
  background-color: $color-gray !important;
  border-color: transparent;
}

input.btn-cancel[type=submit]:disabled {
  border-color: $color-gray;
  color: $color-gray;
  background-color: $color-white !important;
}

select {
  line-height: 2.813rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: '';
  text-indent: 0.01px;
  /* Removes default arrow from firefox*/
  text-overflow: "";
  /*Removes default arrow from firefox*/
  padding: 0 0.813em 0 0.5rem;
  background-position: right center;
}

select::-ms-expand {
  display: none;
}

.clearable {
  padding-right: 2.125rem;
}

.clearable::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.form-group {
  .small {
    width: 9.000rem;
  }
  .radio {
    display: inline-block;
    width: 100%;
    label {
      font-weight: normal;
      padding: 0 0 0 2.125rem;
      margin: 0;
      position: relative;
      z-index: 10;
    }
  }
  input[type="radio"] {
    position: absolute;
    opacity:0;
  }
  .radio-container {
    position: relative;
    float: left;
    margin: 0 2.5rem 0.375rem 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .check {
    display: block;
    background: $color-white;
    position: absolute;
    border: 1px solid $color-silver;
    border-radius: 100%;
    height: 1.563rem;
    width: 1.563rem;
    top: 0.063rem;
    left: 0px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
  }
  .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 0.938rem;
    width: 0.938rem;
    top: 0.250rem;
    left: 0.250rem;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
  input[type=radio]:checked ~ .check {
    background: $color-white;
  }
  input[type=radio]:disabled ~ .check {
    background: $color-gallery;
  }
  input[type=radio]:checked ~ .check::before {
    background: $color-green;
  }
}

.checkbox {
  position: relative;
  margin: 0.625rem 0 0;
  display: inline-block;
  width: 100%;
  span {
    float: left;
    width: 85%;
    margin: -0.313em 0 0 0;
  }
  input[type=checkbox] {
    float: left;
    visibility: hidden;
  }
  input {
    width: 1.500rem;
    height: 1.500rem;
    margin: 0 1.063rem 0 0;
    position: relative;
    &.alert-danger ~ label {
      border-color: $color-crimson;
    }
  }
  label {
    cursor: pointer;
    position: absolute;
    width: 1.500rem;
    height: 1.500rem;
    top: 0;
    left: 0;
    margin: 0;
    background: $color-white;
    border: 1px solid $color-silver;
  }
  label:after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 1.188rem;
    height: 0.563rem;
    background: transparent;
    top: 0.313rem;
    left: 0.125rem;
    border: 3px solid $color-green;
    border-top: none;
    border-right: none;
    transform: rotate(-50deg);
  }
  input[type=checkbox]:checked ~ label:after {
    opacity: 1;
  }
}
