$namespace: "ps";

header {
  margin-bottom: 1.5rem !important;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 5px #333;
}

.#{$namespace}-header {

  &-item {
    font-weight: 500;
    margin: -8px 0 5px 0;
    position: inherit;
    font-size: .875rem;
    line-height: 1.059rem;
    margin-left: 1.563rem;

    @include breakpoint(small down) {
      // margin-left: 10px;
      font-size: .625rem;
      margin: -4px -5px 0 0;
    }

    .ico-bw {
      color: #808080;
      float: left;
      font-size: 1.25rem;
      margin: -5px 0 0 0;
      padding: 0 0 3px 21px;

      @include breakpoint(small only) {
        font-size: 1.125rem;
        margin: 0;
      }
    }

    .icon-basket {
      font-size: 2.125rem;
      margin: 0;
      padding-left: 1.1rem;

      @include breakpoint(small only) {
        font-size: 1.375rem;
      }
    }

    &-cart {

      &-txt {
        clear: both;
        float: left;
        color: #333;
        margin: -3px 0 0 0;
        @include breakpoint(small only) {
          // display: none;
        }
      }

      &-counter {
        background-color: $color-red;
        border-radius: 1rem;
        color: $color-white;
        font-size: 0.750rem;
        font-weight: 500;
        min-width: 1.250rem;
        min-height: 1.250rem;
        position: relative;
        border: 2px solid $color-white;
        text-align: center;
        left: -0.5rem;
        top: -0.250rem;
        padding: 0 0.4rem;
      }

      &-price-warranty {
        width: 10rem;
        font-weight: 600;
        color: #fff;
        padding: 1rem;
        background-color: grey;
        text-align: center;
        margin-right: 0;
        margin-bottom: 0;
        margin-top: -1.57rem;
        font-size: 100%;
        line-height: 1.5;

        @include breakpoint(small down) {
          width: 4.6rem;
          font-size: 0.875rem;
          word-break: break-word;
          padding: 1rem .5rem .5rem .5rem;
          margin-left: 0.625rem;
        }
      }
    }

    &-login {
      &-txt {
        clear: both;
        float: left;
        color: #333;
        @include breakpoint(small only) {
          margin: -1px 0 0 4px;

        }
      }
    }


    &-loggedin {

      .icon-user {

        position: relative;

        &.open {
          margin: .4em 1.55rem .125rem .938rem;
          width: 1.25rem;
          height: .875rem;

          &:before {
            display: none;
          }
        }
      }

      @include breakpoint(large) {
        position: relative;

        &:hover {
          box-shadow: -1px -1px 10px -2px #ccc;
          // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
          padding: .625rem .938rem 1.25rem;
          margin: -1.125rem -.938rem -1.125rem .63rem;

          & .icon-user-header:before {
            color: $color-baywagreen;
          }

          & .ps-header-item-loggedin-text {
            color: $color-baywagreen;
          }

          &:before {
            content: "";
            background-color: #fff;
            width: 100%;
            height: .938rem;
            display: block;
            position: absolute;
            float: left;
            bottom: -.313rem;
            left: 0;
            z-index: 101;
          }

          & .ps-header-item-loggedin-box {
            display: block !important;
            box-shadow: -1px -1px 10px -2px #ccc;
            //box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
          }
        }
      }

      &-text {
        clear: both;
        float: left;
        color: #333;
        @include breakpoint(small only) {
          margin: -1px 5px 0 2px;
          float: right;
          &.open {
            color: $color-baywagreen;
          }
        }

        &-wrapper {
          clear: both;
          float: left;
          position: relative;
          @include breakpoint(medium down) {
            position: inherit;
          }
        }
      }

      &_mobile-close-icon {
        &.open {
          display: block;
        }

        position: absolute;
        height: .125rem;
        width: 100%;
        opacity: 1;
        left: 0.25rem;
        background: $color-baywagreen;
        display: none;
        transform: rotate(0deg);

        &:nth-child(1) {
          top: 1.000rem;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: .75rem;
        }

        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: .75rem;
        }

        &:nth-child(4) {
          top: 1.000rem;
          width: 0;
          left: 50%;
        }
      }

      &-box {
        background-color: #fff;
        box-shadow: -1px -1px 10px -2px #ccc;
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        display: none;
        left: -15rem;
        padding: 1.563rem 1.25rem 0;
        position: absolute;
        top: 2.0rem;
        width: 330px;
        z-index: 100;

        @include breakpoint(medium down) {
          box-shadow: -1px -1px 10px -2px #ccc;
          // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
          border-top: 1px solid $color-baywagrey3;
          border-bottom: 1px solid $color-baywagrey3;
          left: 0;
          top: 7rem;
          width: 100%;
        }
        @include breakpoint(small only) {
          top: 3.25rem;
          width: 100%;
        }

        & .icon-user {
          float: left;
          margin: 0 .938rem 0 0;
          font-size: 1.5rem;
          padding-left: 0;
          color: $color-darkgrey !important;
          &:hover {
            color: $color-darkgrey !important;
          }
        }

        &-title {
          float: left;
          font-size: 1rem;
          padding: .188rem 0 0;
        }

        &-logoutform {
          &-wrapper {
            float: right;
          }

          & input {
            &:hover {
              background: transparent;
            }

            cursor: pointer;
            border: none;
            padding: .188rem 0 0;
            margin: 0;
            height: auto;
            line-height: inherit;
            background: transparent;
            color: $color-baywagreen;
            text-decoration: underline;
            font-weight: inherit;
            font-size: .875rem;
          }
        }

        &-info {
          display: inline-block;
          width: 100%;

          &-salutation {
            clear: both;
            float: left;
            position: relative;
            font-size: .875rem;
            line-height: 1.25rem;
          }

          &-firstname {
            margin: 0 0 0 .25rem;
            font-weight: 600;
            font-size: .875rem;
            line-height: 1.25rem;
          }

          &-lastname {
            margin: 0 0 0 .25rem;
            font-weight: 600;
            font-size: .875rem;
            line-height: 1.25rem;
          }
        }

        & .bw-form__btn {
          font-size: 1rem;
          margin: 1.875rem 0;
        }
      }
    }
  }
  .telephone {
    &-icon {
      width: auto;
      height: 20px;
      margin: -6px 0 -2px 0;

    @include breakpoint(medium) {
        width: auto;
        height: 28px;
        margin: 4px 0 7px 0;
      }
    }
    &-text {
      margin: -1px 15px 0 5px;
      @include breakpoint(medium) {
        margin: -1px 0 0 0;
      }
    }
  }
  a.claim-a {font-weight: 400};
}

header nav {
  margin: 0;
  display: block;
  ul, li {
    list-style: outside none none;
    line-height: 2rem;
    margin: 0;
    padding: 0;
		@include breakpoint(small only) {
			line-height: 1.6rem;
			}

      li { float: left;
          margin: 0.5rem 2rem 1.25rem 0;
          @include breakpoint(medium down) {
            margin: 0.5rem 1rem 1.25rem 0;
          }
          font-size: 1rem;
          font-weight: bold;
          a {
            color: $color-baywadarkgrey;
            white-space: nowrap;
            }

    			a:hover {
    	        color: $color-baywagreen;
    	      }

    			a:active {
    		      color: $color-baywagreenhover;
    		    }

          .disabled {
            color: $color-baywa-silver-grey;
            }

        .enabled {
          color: $color-baywagreen;
        }
          @include breakpoint(medium only) {
            font-size: 0.875rem;
            margin-top: 0.6rem;
          }
          @include breakpoint(small only) {
              margin: 0.5rem 2rem 1.25rem 0;
            }
         }
  }

  }

// @media (min-width: 768px) {
.navigationbar-container {
    // position: relative;
    background-color: $color-baywagrey3;
    // box-shadow: 0 0 5px #333;
    // z-index: -5;
}
// }

// @media (min-width: 768px) {
.navigation-bar {
    position: static;
    display: flex;
    width: 100%;
    height: 45px;
    overflow: visible;
    transform: translate(0);
    z-index: 0;
    // padding: 0 10px;
		@include breakpoint(small only) {
			height: 40px;
			}
    .navigation__sub-nav {
      display: none;
    }
}

.navigation__sub-nav {
  display: -ms-flexbox;
  display: flex;
  left: 0;
  margin-left: -3.125rem;
  overflow: hidden;
  transition: height .3s ease-in-out;
  width: calc(100% + 6.25rem);
  position: absolute;
  top: 45px;
  z-index: -1;
  background-color: white;
  padding-left: 50px;

  .navigation__sub-nav-list {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-flow: column wrap;
  }

  .navigation__sub-nav-item {
    font-weight: 500;
    margin: 0;
  }
}
// }

@media (min-width: 1024px) {
.navigation-bar {
    padding: 0 0 0 calc(50% - 570px);
}
}

.navigation__mobile-menu {
  margin: -3px 20px 0 -3px;
  float: left;
}

.navigation__mobile-menu-icon {
  margin: 0 auto;
  padding: 0 0 1px 9px;
  color: $color-darkgrey;
  float: left;
  font-size: 18px;
}


.navigation__mobile-container {
  background: #fff;
  overflow: auto;
  padding-bottom: 10.5rem;
  position: absolute;
  width: 100%;
  height: 1000px;
  left: 0;
  z-index: 4;
  display: none;

  .grid-container {
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

@media (min-width:600px) {
  .navigation__mobile-container {
    width: 50%;
  }
}

.navigation__mobile-overlay {
  background-color: rgba(0,0,0,.8);
  border: none;
  bottom: 0;
  height: 100%;
  left: 0;
  padding: 0;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  opacity: 1;
  display: none;
}

.top-nav {
  background-color: #fff;
  float: none!important;
  position: relative;
  z-index: 5;
}

.navigation__mobile-item {
  border-top: 1px solid #fff;
  position: static;
  margin: 0;
  cursor: pointer;
  list-style: none;
  padding: 0;

  &:first-of-type {
    border-top: none;
  }
}

.navigation__mobile-item-link {
  background: #f4f4f4;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  display: inline-block;
  line-height: 1.5rem;
  padding: .75rem 1rem;
  text-decoration: none;
  width: 100%;

  span.icon {
    display: inline-block;
    color: #999;
    height: 1rem;
    width: 1rem;
    float: right;
  }

  &.is-open {
    color: #fff !important;
    background: $color-baywagreen;
    span.icon {
      color: #fff;
      transform: rotate(180deg);
    }
  }

  &.current {
    font-weight: 600;
    font-style: normal;
    color: $color-baywagreen;
  }
}

.navigation__mobile-sub-nav {
  display: none;
  ul {
    list-style: none;
    padding-left: 50px;
    li {
      padding: 10px 0 10px 0;
      a {
        color: #333;
        &:hover {
          color: $color-baywagreen;
        }
        &.current {
          font-weight: 600;
          font-style: normal;
          color: $color-baywagreen;
        }
      }
    }
  }
}

.icon-login {
  height: 16px;
  width: auto;
  @include breakpoint(medium) {
    height: 38px;
  }
}