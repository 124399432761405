// general used elements
h1, .h1 {

	color: $color-baywagreen;
	font-size: 1.75rem;

	&.big {
			font-size: 2.5rem;
			line-height: 2.813rem;
			@include breakpoint(small only) { font-size: 1.375rem; }
		}
}

.float-left { float: left; }
.float-right { float: right; }
.float-center { display: block; margin-right: auto; margin-left: auto; }
.text-center { text-align: center; }
.small-txt { font-size: 90%; }
.no-wrap { white-space: nowrap;}
.hidden { display:none; }
a.underline { color: $color-body; display: block; text-decoration: underline; }

// labels used as buttons competencecert
.btn-label {
	background-color: $color-green;
	color: $color-white !important;
	padding: 0 !important;
	cursor: pointer;
	font-weight: bold;
	height: 2.813rem;
	line-height: 2.813rem;
	text-align: center;
	&:hover { background-color: $color-darkgrey; }
}
// searchfield
#search-field {
    float: left;
    position: relative;
    margin: 0 0 1.250rem;
    input { margin: 0; padding: 0 2.813rem 0 0.625rem; }
    span {
      cursor: pointer;
      font-size: 1.625rem;
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;
      color: $color-darkgrey;
      &:hover { color: $color-green; }
    }
}
// content
main {
	padding: 0 0.3125rem 0;
	ul, li { list-style: outside none none; margin: 0; padding: 0; }
		position: relative;
		.white-box {
			background: $color-white;
			padding: 1.25rem;
			position: relative;
			float: left;
			margin: 0 0 1.563rem 0;
			width: 100%;
		}
		.white-box-back {
			padding:0 1.25rem;
			margin: 0 0 0.938rem 0;
			.back {
				color: $color-body;
				display: block;
				font-weight: bold;
				height: 2.813rem;
				line-height: 2.813rem;
				.arrow {
					width: 0;
					height: 0;
					border-top: 0.375rem solid transparent;
					border-bottom: 0.375rem solid transparent;
					border-right: 0.500rem solid $color-body;
					float: left;
					margin: 1.063rem 0.563rem 0 0;
				}
				span { float: left;	}
			}
		}
		.white-box-pagination {
			.white-box { text-align: center; }
		}
		.content-area, article.main { padding: 0; }
}

@media (max-width: 47.9375em) {
	main, .content-side, #content {
		padding: 0;
	}
	main #content {
		nav { padding: 0.595rem 0.9375rem; }
		.white-box { padding: 0.9375rem;
			&.white-box-back { padding: 0 1.25rem; margin: 0 0 0.625rem 0; }
		}
		.mobile-edge-padding { padding: 0px 0.9375rem; }
	}
	.small_padding-top {
		padding-top: 0.9375rem;
	}

	h3 {
		font-size: 1.375rem;
	}

	h4 {
		font-size: 1.375rem;
	}
}

.pricing {
	margin-bottom: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-left-right {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.no-padding-small-only {
	@include breakpoint(small only) {
		padding: 0 !important;
	}
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-left-small-large {
	@include breakpoint(small only) {
		padding-left: 0 !important;
	}
	@include breakpoint(large only) {
		padding-left: 0 !important;
	}
}

.no-padding-small-large {
	@include breakpoint(small only) {
	  padding-left: 0 !important;
	  padding-right: 0 !important;
	}
	@include breakpoint(large up) {
	  padding-left: 0 !important;
	  padding-right: 0 !important;
	}
}

.no-padding-left-small-only {
	@include breakpoint(small only) {
		padding-left: 0 !important;
	}
}

.no-padding-left-medium {
	@include breakpoint(medium only) {
		padding-left: 0 !important;
	}
}

.no-padding-right-medium {
	@include breakpoint(medium only) {
		padding-right: 0 !important;
	}
}

.no-padding-left-medium-up {
	@include breakpoint(medium up) {
		padding-left: 0 !important;
	}
}

.margin-bottom-30px {
	margin-bottom: 1.875rem;
}


@include breakpoint(medium only) {
  .mediumonly-margin-top-32px {
    margin-top: 2rem;
  }
}

@include breakpoint(medium) {
  .medium-margin-top-10px {
    margin-top: .625rem;
  }

  .medium-margin-bottom-24px {
    margin-bottom: 1.5rem;
  }
}

.message {
	position: relative;
	border-width: 1px;
	padding: 0.5rem;
	padding-left: 2.5rem;
	margin-bottom:0.5rem;

	&--error {
		color: $color-red;
		background-color: $color-alertred;
		border: 1px solid $color-red;
	}

	&--warning {
		color: $warning-color;
		background-color: $color-alertred;
		border: 1px solid $warning-color;
	}

	&:before {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'icomoon' !important;
		speak: none;
		font-size: 1.5rem;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		content: "\e920";
		position: absolute;
		display: block;
		top: 50%;
		left: 0.5rem;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}

// deactivates browser specific styling for fields of type number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

// clears the 'X' from Internet Explorer */
input::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

.footnote {
	font-size: 0.8rem;

	.icon-checkmark:before {
		top: 0.2rem;
	}
}
