$namespace: "ps";

.#{$namespace}-cart-address {
  &__overview {
    background-color: $color-white;
    padding: rem-calc(30) rem-calc(20) rem-calc(30) rem-calc(30);
    margin-bottom: rem-calc(20);
  }

  &__input {
    border-radius: 0 !important;
  }

  &__text{
    padding-left: 0px;
  }

  &__change-address {
    text-decoration:underline;
    @include breakpoint(small only) {
      display: none;
    }
    &-small {
      @include breakpoint(small up) {
        display: none;
      }
      @include breakpoint(small only) {
        display: inline;
      }
      float: right;
      padding-top: 2px;
      text-decoration:underline;
    }
  }
}