$namespace: "ps";

.#{$namespace}-result-list {
  &__item {
    background-color: $color-white;
    padding: rem-calc(20) rem-calc(20) rem-calc(15) rem-calc(20);
    display: inline-block;
	margin-bottom: rem-calc(20);
    position: relative;

	@include breakpoint(small only) {
	  padding: rem-calc(20);
	  display: inline-block;
	}

    @include breakpoint(medium down) {
      margin-right: 0.750rem;
    }

    &__part-1{
      padding-left: 0.5rem;
    }
    &__part-2 {
      padding-left: 0.5rem;
      padding-top: 1.5rem;
    }
	&__part-3 {
      position: relative;
      padding-left: 0.5rem;
      padding-top: 30px;

      @include breakpoint(medium up){
        padding-top: 104px;
        > div:first-child {
          position: absolute;
          bottom: 0;
          right: 0;
          padding-right: inherit;
        }
      }
	}

    &__part-4 {
      padding-left: 0.5rem;
      padding-top: 20px;
    }

    &__part-5 {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        padding-top: 50px;

      @include breakpoint(small only) {
	  padding-top: 1rem;
	  }
    }

    &__icon-oil {
      position: absolute;
      color: $color-green;
      font-size: 2.5625rem;
      left: rem-calc(-5);
      z-index: 15;
    }
    &__title {
      margin-bottom: 0;
      @include breakpoint(small only) {
        font-size: 1.5625rem;
      }
    }

    &__price {
	  $root:&;
      &--small {
		font-size: rem-calc(18);
		@include breakpoint(small only) {
		  #{$root}__unit {
			float: left;
			margin-right: rem-calc(10);
			// margin-top: rem-calc(-3);
		  }
		}
      }
	  &--big {
        text-align: center;
		font-size: rem-calc(24);

        @include breakpoint(medium up){
          text-align: right;
        }
	  }
	  &__unit {
		font-weight: bold;
		color: $color-green;
	  }
	  &__info {
		font-size: rem-calc(14);
        display: inline;

        .ggv-info-toggle {
            color: $color-green;
            text-decoration: underline;
        }

        &-date {
          font-weight: 600;
          font-size: .875rem;
        }

        &-text {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            margin-top: -1rem;
            right: 4rem;
            border-width: 0.500rem;
            border-style: solid;
            border-color: transparent transparent #333 transparent;

            @include breakpoint(small only) {
              right: 50%;
            }
          }

          text-align: left;
          position: absolute;
          left: 0;
          background-color: #333;
          color: #fff;
          font-weight: 500;
          font-size: .875rem;
          margin-top: 1rem;
          padding: .938rem .625rem;
          width: 100%;
          z-index: 10;

          @include breakpoint(small only) {
           margin-top: 6rem;
          }
        }
	  }
    }
    &__info-list {
      color: $color-black;
      padding-left: rem-calc(20);
    }

    &__info-item {
	  position: relative;
      padding-bottom: rem-calc(10);

      &:last-child {
        padding-bottom: 0;
      }

      .icon-checkmark {
        color: $color-green;
        font-weight: bold;
      }
      .icon-checkmark:before {
        position: absolute;
        left: rem-calc(-20);
      }
      .advantage {
      	padding-left: 0.75rem;
      }
    }
    &__info-more {
	  position: relative;
      color: $color-green;
      font-weight: bold;
      margin-top: rem-calc(10);
      .icon-arrow_right:before {
        position: absolute;
		left: rem-calc(-20);
		top: rem-calc(2);
      }
    }

    &__add-to-cart {

      .icon-basket {
        font-size: rem-calc(20);
        vertical-align: middle;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

/* Artikel-Claim */

.article-claim {
    font-size: 0.875rem;
    font-weight: 600;
}


/* Angebots-Badge */

.ribbon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    border-top: 6.875rem solid $color-baywayellow;
    border-left: 6.875rem solid transparent;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    z-index: 10;
    margin-left: auto;
}

  .ribbon-wrapper .ribbon {
      position: absolute;
      transform: translate(-50%) rotate(+45deg);
      transform-origin: center bottom;
      text-align: center;
      z-index: 11;
    }

    .ribbon-wrapper .ribbon_heizoel {
      bottom: 3.625rem;
      right: -2.5rem;
    }

    .ribbon-wrapper .ribbon_diesel {
        bottom: 3.625rem;
        right: -2.5rem;
      }

    .ribbon-wrapper .ribbon_holz_pellets {
        bottom: 4rem;
        right: -1.5rem;
      }

/* Holzpellets */

.pellets_price {
  height: rem-calc(58);
}


.pellets_cta {
    margin-top: 2.5rem;
}
