$namespace: "ps";

.#{$namespace}-changeShippingAddress-form {
  input[type=submit]{
    float: right;
  }

  input[type=radio]:checked + label{
    font-weight: bold;
  }

  h1 {
    margin-bottom: 1.8rem;
    padding: 0;
  }

  p {
    margin: 0 0 15px 0;
    padding: 0;
  }

  .radio-container {
    margin: 5px 0 10px 0;
    padding: 0;

    &:first-of-type {
      margin-top: 15px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
