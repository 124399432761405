$namespace: "bw";

.#{$namespace} {
	&-smallfooter {
		background-color: $color-baywadarkgrey;
		color: $color-baywawhite;
		float: left;
		margin-top: 1.875rem;
		padding: 0.875rem 0 0;
		width: 100%;
		font-size: 0.875rem;
		.medium-10 { float: right; @include breakpoint(small only) { width: 100%; } }
		.medium-2 { float: left; margin-top: 0;}
		div, p { flex: unset; display: block; }
		p { padding-top: 0.250rem; }
		a, a:hover { color: $color-baywawhite; }
		ul {
			margin: 0 0 0.938rem 0;
			padding: 0;
			float: right;
			@include breakpoint(small only) { float: left; }
			li {
				float: left;
				height: 1.563rem;
				line-height: 1.563rem;
				list-style: outside none none;
				padding: 0;
				&::after {
					content: " | ";
					margin: 0 0.875rem;
					
					@media (max-width: 374px) {	
					margin: 0 0.35rem;
					}
				}
				&:last-child:after {
					content: "";
					margin: 0;
				}
				a { text-decoration: none; }
			}
		}
		@include breakpoint(small only) {
			margin-top: 0.625rem;
		}
	}
}