$namespace: "ps";

.#{$namespace}-breadcrumb {
	list-style: outside none none;
	margin: 1.25rem 0 1.25rem;
	display: inline-block;

	&__section {
		@include breakpoint(small only) {
			display: none;
		}

		&-back {
			@include breakpoint(small up) {
				display: inline;
			}
		}

		float: left;
		font-size: 0.875rem;
		margin: 0 0.313rem 0 0;

		&:last-child {
			a:after {
				content: '';
			}
		}
		&-link {
			color: $color-body;
			&:hover {
				color: $color-green;
			}

			@include breakpoint(small up) {
				&:after {
					content: '/';
					margin-left: .4rem;
				}
			}

			&-back {
				font-weight: bold;
				&:before {
					content: "\e901";
					font-family: 'icomoon' !important;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					font-size: rem-calc(12px);
					background-color: $color-baywagrey1;
					padding: 0.187rem 0.25rem 0.187rem 0.125rem;
					border-radius: 1rem;
					color: $color-baywawhite;
					speak: none;
					cursor: pointer;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					margin-right: .4rem;
				}
				&:after {
					content: '|';
					margin-left: .4rem;
				}
				@include breakpoint(small only) {
					&:after {
						content: '';
					}
				}
			}
		}
	}
}

.#{$namespace}-checkout-breadcrumb {
	font-size: 1.125rem;
	font-weight: bold;
	color: $color-baywagrey1;
	padding-bottom: 2rem;

	&-item {
		background-color: $color-white;
		text-align: center;
		padding: 1rem .8rem .8rem .8rem;
		white-space: nowrap;

		&:first-child {
			text-align: left;
			padding-left: rem-calc(15);
		}

		&:nth-child(2) {
			text-align: left;
		}

		&:nth-child(3) {
			text-align: center;
		}

		&:last-child {
			text-align: right;
			padding-right: rem-calc(15);
		}
		&-active {
			color: $color-green;
		}
		&-link {
			color: $color-baywadarkgrey
		}
	}
}


@media (max-width: 1023px) {
	.#{$namespace}-checkout-breadcrumb {
		font-size: .875rem;
	}
}
