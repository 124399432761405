$break-width: 1024px;
@mixin table {
  @media (max-width: #{$break-width}) {
    @content;
  }
}
.tr-clickable {
	border-collapse: separate;
	border-spacing: 0.125rem 0.625rem;
	margin: 0 0 0.938rem 0;
	&.returns_order_overview {
		thead tr th { color:$color-white; }
	}
	tbody td { padding: 0.5em 1.0625rem; }
	thead, tbody, tfoot { border: none; }
	thead tr {
		th { padding: 0.688rem 1.0625rem; }
	}
	tbody tr {
		padding: 0;
		td {
			line-height: normal;
			padding: 0;
			&.invoice-nr, &.contract-nr { height: 3.438rem; }
			&.invoice-payment {
				position: relative;
				a { padding-right: 3.438em; }
			}
			a {
				color: $color-body;
				display: block;
				padding: 0.313rem 1.0625rem;
				position: relative;
				width: 100%;
				@include table { padding: 0.313rem 0.625rem; }
				&:hover { color: $color-body; }
				span { line-height: normal; }
			}
		}
	}
	td.document-cell-with-target a::after, td.invoice-payment a::after {
		font-family: 'icomoon';
		color: $color-green;
		content: "\e902";
		font-size: 1.125rem;
		height: 1rem;
		position: absolute;
		right: 1.563rem;
		top: 50%;
		transform: translateY(-50%);
		width: 0.625rem;
	}
	td.document-cell-with-target a { 
		padding: 0.313rem 1.875rem 0.313rem 1.0625rem;
		&::after { right: 0.938rem; }
	}
}
thead {
	height: 1.688rem;
	line-height: 1.688rem;
	span { color: $color-white; }
	a, a:hover { color: $color-white; float: left; }
	.sorted-desc::after, .sorted-asc::after {
		width: 0;
		height: 0;
		content: "";
		border-left: 0.375rem solid transparent;
		border-right: 0.375rem solid transparent;
		margin: 0.313rem 0 0 0.313rem;
		position: absolute;
		right: -.3em;
	}
	.sorted-desc::after {
		border-top: 0.563rem solid $color-white;
	}
	.sorted-asc::after {
		border-bottom: 0.563rem solid $color-white;
	}
}
tbody {
	tr {
		&:nth-child(even) { background: $color-white; }
	}
}	
.shipping-condition {
	width: 0.063rem;
	text-align: center;
	a { padding-top: 2.000rem!important; }
	span {
		font-size: 0.750rem;
		&.deliver::before {
			font-family: 'icomoon';
			content: "\e90b";
			color: $color-darkgrey;
			display: block;
			height: 1.000rem;
			font-size: 1.563rem;
			position: absolute;
			left: 38%;
			top: 1.000rem;
			width: 1.250rem;
		}
		&.pickup::before {
			font-family: 'icomoon';
			content: "\e909";
			color: $color-darkgrey;
			display: block;
			height: 1.000rem;
			font-size: 1.250rem;
			position: absolute;
			left: 39%;
			top: 1.000rem;
			width: 1.188rem;
		}
	}
}
// styling for table to be boxes
@media (max-width: 63.938em) {
	.main .returns_order_overview {
		.last { text-align: left; }
	}
	.main .returns_order_overview { width: 100%; margin: 0; }
	.main .returns_order_overview .overview-return {
		width: 100%;
		float: left;
		.return-entry-description {
			padding: 0.5rem 1.0625rem 0.5rem 6rem;
			strong { line-height: normal; }
		}
		.last { text-align: left; }
		td { padding-top: 0; padding-bottom: 0; }
		.cashback { font-weight: normal; }
		tr {
			display: block;
			float: left;
			padding: 0.938rem 0;
			margin: 0 0 1.250rem 0;
			width: 100%;
		}
	}
	#content #return_form .tr-clickable {
		width: 100%;
		tbody { display: block; width: 100%; }
		td {
			width: 100%;
			clear: both;
			&.first {
				margin: 0 0 0.625rem;
				min-height: 3.438rem;
				img { max-height: 3.5rem; }
			}
		}
		span { padding: 0.375rem 0.313rem 0 0; }
		.unit-info { width: auto; }
	}
	#return_form {
		.tr-clickable {
			.hide-for-small-only { display: block; }
		}
	}
	#oncall-value .right-align-desktop .net-price { font-weight: normal; }
	#competence-cert { 
		margin: 0 0 1.250rem 0; width: 100%!important; 
		tbody { background-color: transparent; }
		tbody, tbody tr { width: 100%; float: left; }
		tbody tr { background-color: #fefefe; position: relative; margin-bottom: 0.938rem; height: auto;  }
		td.com-cert { padding: 0;
			span { width: 100%; 
				.cert-select { padding: 0 3.625rem 0 0; background-color: $color-darkgrey; margin: 0 0 0.625rem; }
				.nice-select { margin: 0.313rem 0 0.313rem 0.313rem; }
			}
			a, .table-margintop { background-color: $color-darkgrey; color: $color-white; margin: 0 0 0.625rem 0; padding: 0.938rem !important; text-decoration: none;}
		}
		td.competence-cert-td { width: 100%; }
		.competence-cert-delete {
		    padding: 0;
			position: absolute;
			right: -0.438rem;
			top: 0.750rem;
		    .icon-delete { color: $color-white; }
		}
		tr.cert-demand { 
			p { width: auto; padding: 0; }
			td { 
			padding-top: 0.938rem; 
			padding-bottom: 0.938rem;
				.cert-btns { 
				width: 100%; 
				float: left;
				margin: 1.250rem 0 0.625rem;
					a.btn-label {
						padding: 0 !important;
						height: 2.813rem;
						line-height: 2.813rem;
					}
				} 
			} 		
		}
	}
	.tr-clickable {
		&.table-striped {
			.sorted.right-align-desktop { float: left; }
			.right-align-desktop { text-align: left; }
		}
		border-spacing: 0.125rem 1.250rem;
		display: block;
		float: left;
		margin-top: -1.563rem;
		margin-bottom: -1.250rem;
		position: relative;
		tbody tr td {
			a {
				line-height: normal;
				height: auto;
				float: left;
				padding: 0.375rem 0.938rem!important;
			}
			&.invoice-nr, &.contract-nr  { padding: 0.938rem 0!important; height: auto; }
			&.invoice-ship a, &.shipping-condition a, &.top-space a { padding: 0.938rem 0.938rem 0.375rem!important; }
			&.invoice-payment a, &.btm-space a { padding: 0.375rem 0.938rem 0.938rem!important; }
		}
		td.invoice-payment a::after, .hide-for-small-only { display: none; }
		.show-for-small-only, .sort-filter { display: block!important; }
		td {
			float: left;
			padding: 0 0.938rem;
			line-height: 2.000rem!important;
			width: 100%;
			.table-margintop { 
				line-height: normal; 
				.small-txt { font-size: 80%; } 
			}
			strong, span {
				position: relative;
				display: block;
				float: left;
			}
		}
		.shipping-condition {
			text-align: inherit;
			span {
				font-size: 1.000rem;
				text-align: left;
				margin: 0;
				&.pickup, &.deliver { padding: 0 0 0 1.563rem; }
				&::before { left: 0; top: 0.063rem; }
				&.deliver::before { left: -0.188rem; top: -0.125rem; }
			}
		}
		.article-nr, .tb-top { padding-top: 0.938rem; }
		.total-price, .tb-btm { padding-bottom: 0.938rem; }
		.with-link {
			font-weight: bold;
			background-color: $color-darkgrey;
			color: $color-white;
			position: relative;
			a {
				color: $color-white;
				padding: 0.938rem 0!important;
				&:hover { color: $color-white; }
			}
		}
		.invoice-nr, .contract-nr {
			font-weight: bold;
			background-color: $color-green;
			color: $color-white;
			position: relative;
			a {
				color: $color-white;
				padding-right: 2.813em;
				&:hover { color: $color-white; }
			}
			&::after {
				display: block;
				height: 1.250rem;
				position: absolute;
				right: 1.875rem;
				top: 0.938rem;
				width: 0.813rem;
				font-family: 'icomoon';
				color: $color-white;
				content: "\e902";
				font-size: 1.563rem;
			}
			.show-for-small-only { margin: 0 0.600em 0 0; }
		}
	}
	.order-result {
		&.tr-clickable { border-spacing: 0 5.063rem; margin-bottom: -5.063rem; }
		.invoice-nr { margin-top: -6.625rem; }
	}
}

// order & invoice detailpage
#detail-box { padding: 1.438rem 0 0 0; }
#detail-valueinfo { margin-top: 0.938rem; line-height: 180%; }
#detail-aside {
	&.float-right {
		a { float: right;}
	}
	a {
		color: $color-body;
		display: block;
		margin: 0 0 1.250rem 0;
		position: relative;
		float: left;
		&:hover { color: $color-body; }
	}
	.order-bills .nice-select { margin: 0 0 1.25rem 0; }
	strong {
		padding: 0 0 0 2.188rem;
		&::before {
			background: url("../../images/icon_sprite.svg") no-repeat -1px -2696px;
			content: '';
			height: 1.813rem;
			position: absolute;
			left: 0;
			top: -0.188rem;
			width: 1.688rem;
		}
	}
	option {
		&::before {
			background: url("../../images/icon_sprite.svg") no-repeat -1px -2745px;
			content: '';
			height: 1.188rem;
			position: absolute;
			left: 0;
			top: 0;
			width: 1.188rem;
		}
	}
}
.table-striped {
	th .sorted {
		line-height: 1.125rem;
		position: relative;
		white-space: nowrap;
		padding-right: 1rem;
		&.two-lines { white-space: inherit; }
	}
	.sorted.right-align-desktop { float: right; }
	.right-align-desktop { text-align: right; }
}
.table-detailpage {
	.table-margintop { padding: 0.938rem 0; display: block; }
	.detail-s { width: 10%; }
	.detail-m { width: 20%; }
	.detail-l { width: 30%; }
	.item-link { text-decoration: underline; padding: 1rem 0; }
	tbody tr td {
		padding: 0 1.0625rem;
		.nice-select { margin: 0.25rem 0; }
	}
	.net-price { font-weight: bold; }
}
.return-headline {
	background-color: $color-white;
	color: $color-green;
	font-size: 1.250rem;
	margin-bottom: 0.938rem;
	&.show-for-small-only {
		margin: 0.938rem 0 0 0;
		padding: 0.938rem 1.25rem 0;
		float: left;
		width: 100%;
	}
}
#return_form, #no-return {
	.check-all {
		.checkbox { margin: 0.500rem 0 0.750rem; }
	}
	.checkbox span { margin-top: 0.125rem; }
	.pickup-address { margin: 1.563rem 0 0.938rem; display: none; 
		span { display: inline-block; margin: 0 0 0.625rem 0; }
		.add-new { margin-bottom: 0.938rem; }
	}
	.tr-clickable {
		border-spacing: 0;
		margin: 0;
		td {
			float: left;
			vertical-align: middle;
			padding: 0;
			width: 50%;
			&.first {
				padding: 0 0 0 6.563rem;
				position: relative;
				label, span { line-height: normal; }
				.img-box { position: absolute; left: 0; }
				.td-container { padding-right: 1.250rem; }
			}
			&.last {
				div.td-container { width: 40%; float: left; }
				div.td-container:last-child { width: 60%; }
			}
			.checkbox { width: auto; float: left; margin: 0.938rem 0 0; }
		}
		.unit-info {
			display: block;
			float: left;
			position: relative;
			width: 9.375rem;
		}
	}
	tbody tr {
		border-top: 1px solid $color-middlegrey;
		display: inline-block;
		width: 100%;
		background: white;
		padding: 1.625rem 0;
	}
	.return-btns {
		width: 100%;
		.columns {
			border-top: 1px solid $color-middlegrey;
			padding-top: 0.938rem;
			padding-left: 0;
			padding-right: 0;
			label { font-weight: normal; }
			input { margin-top: 0; }
		}
	}
}
#return_form .tr-clickable td img {
	max-height: 4.5rem;
	max-width: 3.125rem;
	float: left;
}
#no-return .tr-clickable td img {
	max-height: 2.813rem;
	max-width: 3.125rem;
	float: left;
}
#no-return {
	tbody tr:last-child { padding-bottom: 0!important; }
	tbody td {
		label, span { line-height: normal; }
	}
	&.first label { padding-right: 1.25rem; }
}
#return_form {
	tbody td.first label { margin: 0.188rem 0 0.313rem; }
}
.table-total .back.back-to-order { margin-top: 0; }
#return-confirmation {
	a { color: $color-body; text-decoration: underline; }
}
.return-nr { margin: 0; }
.unit-info {
	select, .nice-select { width: 3.750em; }
	select, div { float: left; }
	.unit-value { margin: 0.875rem 0 0 0.875rem; }
}
.return-intro > span { display: block; }
#content .table-total #retoure-back { margin-top: 2.000rem; }
#content #return_overview_form { clear: none; margin-top: -0.625rem; }
.tr-clickable.returns_order_overview {
	.overview-return { background: transparent; }
	tbody tr { background: white; }
	td { padding: 0 1.0625rem; }
	.return-entry-description {
		padding-left: 5.313rem;
		padding-right: 1.0625rem;
		height: 4.375rem;
		position: relative;
		img {
			left: 1.250rem;
			top: 0.813rem;
			position: absolute;
		}
	}
	.return-description { float: left; }
}
.add-new { margin-bottom: 2.500rem; }
.returns_order_overview {
	.last { text-align: right; }
	td img { max-width: 3.125rem; max-height: 2.813rem; }
}
#competence-cert {
	tr { height: 4.063rem }
	.icon-delete { color: $color-green; font-size: 1.563rem; float: left; padding: 0!important; width: auto; cursor: pointer;  }
	.competence-cert-delete { width: 3.688rem; }
	.competence-cert-td { width: 7.500rem; }
	.com-cert { padding: 0.313em; 
		.nice-select { margin: 0; } 
		a, .table-margintop { padding: 1rem 1.0625rem; }
	}
	.cert-demand { 
		-webkit-box-shadow: 0 0 0.938rem 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 0 0.938rem 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0.938rem 0 rgba(0, 0, 0, 0.1);
		p, input { margin: 0; }
		p { font-weight: bold; width: 18.750rem; float: left;  padding: 0.750rem 0 0; }
		td { 
		padding-top: 0.625rem; 
		padding-bottom: 0.625rem; 
			.cert-btns { 
			width: 16.875rem; 
			float: right;
				.row { display: block; }
				.row::before, .row::after { display: none; }
			}
		}
		display: none;
	}
}

// special for contract details for long table rows - become mobile view earlier
@media (max-width: 69.063em) {
	.contract-details { 
	.tr-clickable {
		&.table-striped {
		.sorted.right-align-desktop { float: left;}
		.right-align-desktop { text-align: left; }
		}
		border-spacing: 0.125rem 1.250rem;
		display: table;
		float: left;
		margin-top: -1.563rem;
		margin-bottom: -1.250rem;
		position: relative;
		thead.show-for-large, .hide-for-small-only { display: none; }
		.show-for-small-only, .sort-filter { display: block!important; }
		td {
			float: left;
			padding: 0 0.938rem;
			line-height: 2.000rem!important;
			width: 100%;
			.table-margintop { 
				line-height: normal; 
				.small-txt { font-size: 80%; } 
			}
			strong, span {
				position: relative;
				display: block;
				float: left;
			}
		}
		.tb-top { padding-top: 0.938rem; }
		.tb-btm { padding-bottom: 0.938rem; }
		.with-link { font-weight: bold; background-color: $color-darkgrey; color: $color-white; position: relative; }
	}
	}
}

@media (max-width: 47.9375em) {	
	.small-float-rgt { float: right; text-align: right; }
	.returns_order_overview {
		margin-left: 0!important;
		margin-right: 0!important;
		&.tr-clickable { margin-top: 0; }
		.overview-return {
			display: block;
			float: left;
			padding: 0 0 5.313rem;
			position: relative;
		}
	}
	#content .retoure-btn {
		padding: 0 0.525rem;
		#return_overview_form { margin-top: -6.250rem; padding-right: 0.625rem; }
	}
	.returns_order_overview { margin-left: 0!important; margin-right: 0!important; }
	.tr-clickable, .table-total nav { width: auto; }
	#detail-box { padding: 0.438rem 0 0; }
	#detail-aside {
		&.float-right { 
			margin: 0;
			a { float: left; margin: 0.625rem 0 0.938rem 0;}
		}
		margin: 1.250rem 0 0;
	}
	.return-nr { margin-bottom: 1rem; }
	#return-order.white-box, #no-return.white-box {
		padding-left: 0!important;
		padding-right: 0!important;
		h1, p, .pickup-address, h2, tr, .return-btns .columns {
			padding-left: 1.25rem;
			padding-right: 1.25rem;
		}
		.pickup-address { 
			border-top: 1px solid $color-middlegrey; 
			margin: 0;
			padding-top: 1.563rem;	
			#create_order_return { margin-bottom: 1.563rem; }
		}
	}
	#content #return_form .tr-clickable {
		td {
			width: 100%;
			clear: both;
			&.first {
				padding: 0 0 0 3.438rem;
				label {	margin: 0 0 0.188rem; }
			}
		}
	}
	#content #no-return .tr-clickable {
		td { width: 100%; clear: both; padding: 0 0 0 3.438rem!important; }
		label {	margin-bottom: 0.313rem; }
	}
	#competence-cert { 
		.competence-cert-delete { right: -0.625rem; } 
	}
	.contract-details-rgt {	margin-top: 0.938rem; }
}