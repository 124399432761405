#more_filter_options_header {
	color: $color-green;
	cursor: pointer;
	font-size: 1.250rem;
	float: left;
	padding: 0 1.563rem 0 0;
	position: relative;
	&::after {
		font-family: 'icomoon';
		color: $color-green;
		content: "\e900";
		display: block;
		height: 0.625rem;
		right: 0;
		top: 0.125rem;
		position: absolute;
		width: 1.000rem;
	}
	&.open::after { content: "\e903"; }
}
.filter-form {
	.underline { margin-top: -2.313rem; }
}
.first-filter {
	margin: 1.438rem 0 1.000rem 0;
	label { font-weight: bold!important; }
	input:disabled { pointer-events: none; cursor: auto; }
}
.more-filter {
	margin: 0.750rem 0 0 0;
	display: none;
	float: left;
	position: relative;
	width: 100%;
	.nice-select { margin: 0 0 1rem; }
}
.contracts-filter { margin: -1.563rem 0 0.625rem 0; }

@media (max-width: 47.9375em) {	
	.first-filter .form-group .radio label { padding: 0; }
	.contracts-filter { margin: -1.250rem 0 -0.625rem; }
	#more_filter_options_header { width: 99%; }
	.filter-form {
		select { display: block!important; }
		.nice-select { display: none;}
		.underline { margin: 0 0 1rem; float: none!important; }
	}
}